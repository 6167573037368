@media (max-width: 1350px) {
    #navbar li a {
        font-size: 16px;
    }
    .hotel_title_block,
    .landing_title_block,
    .epilogue_decor {
        background-size: 1366px auto !important;
    }
}
@media screen and (max-width: 1279px) {
    .clients_slider .slick-arrow {
        margin-top: -13px;
        width: 16px;
        height: 26px;
        background-size: 26px;
    }
    .clients_slider .slick-prev {
        left: -8px;
    }
    .clients_slider .slick-next {
        right: -8px;
    }
}
@media (max-width: 1200px) {
    .member_name {
        padding: 0 10px 10px;
    }
    .clients_slider .slider_line .slide_block {
        width: 16.66%;
    }
    .footer_top_inner .col-lg-3 {
        width: 25%;
        float: left;
        padding: 0 10px;
    }

    .footer_top_inner img {
        max-width: 100%
    }

    .footer_top li a {
        font-size: 13px
    }
    .languages {
        padding-top: 8px;
    }
    .languages ul li {
        margin-left: 15px;
        padding-left: 15px;
    }
    .languages a {

        line-height: 18px;
    }
    .menu_item > a {
        font-size: 14px;
    }
    .header_inner:after {
        display: none;
    }
    .header {
        border-bottom: 1px solid rgba(255,255,255,0.4);
    }



    #navbar {
        padding-right: 10px;
    }

    #navbar li a {
        font-size: 14px;
    }

    #custom_carousel .slide_title, .tour_title_block .tour_title {
        font-size: 40px;
    }

    #custom_carousel .carousel-caption .slide_text {
        font-size: 20px;
        line-height: 30px;
    }

    #custom_carousel .carousel-caption .btn {
        font-size: 18px;
        padding: 10px
    }

    #custom_carousel .carousel-control .glyphicon-chevron-left, #custom_carousel .carousel-control .icon-prev {
        left: 20%
    }

    #custom_carousel .carousel-control .glyphicon-chevron-right, #custom_carousel .carousel-control .icon-next {
        right: 40%
    }

    .travelers_items h4 {
        font-size: 17px;
    }

    .tour_details {
        padding: 15px 0;
    }

    .tm_container {
        padding: 5px 15px;
    }

    .tour_menu li {
        margin-right: 14px;
    }



    #arara_team .member_profesion .text {
        letter-spacing: 0;
        font-size: 14px
    }

    #feedback .feedback_right_block .feedback_ratig_right .fa-star {
        font-size: 20px;
        padding-left: 1px;
    }

    #feedback .feedback_bottom .right .feedback_ratig_left_text {
        font-size: 15px;
    }

    #all_tours .tour_menu_inner {
        max-width: 970px;
    }

    .tour_menu .tour_menu_inner_mobile a {
        margin-right: 18px;
    }

    .tour_menu_inner_mobile {
        width: 85%;
        white-space: nowrap;
        overflow-y: hidden;
        height: 50px;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        float: left;
    }

    #all_tours .tour_menu_inner_mobile {
        width: 100% !important;
        overflow-x: auto;
        padding: 0 15px;
    }
    #all_tours .tour_menu_inner_mobile a:last-child {
        margin-right: 0 !Important;
    }
    .tour_sprite_title {
        line-height: 19px;
    }

}
@media screen and (max-width: 1199px) {
    .tour_menu_inner_mobile {
        width: 80%;
        overflow-x: auto;
    }
    .messenger_block {
        right: 30px;
    }
    .own_tour_head {
        font-size: 50px;
        line-height: 60px;
    }
}
@media screen and (min-width: 1025px) {
    #custom_carousel .slider_img_ {
        height: 762px;
        max-height: 100vh;
    }

}
@media (min-width: 1025px) {
    .tour_menu_inner_mobile_inner{
        margin-top: 7px;
    }
}
@media (max-width: 1024px) {
    .adv_slider .slick-arrow {
        top: -28px;
        height: 28px;
    }
    .character_type{
        text-align: left;
    }
    .header a {
       opacity: 1 !important;
    }
    .tour_menu_inner_mobile_inner{
        top: 8px;
    }
    .review_images {
        margin: 0 -15px;
    }
    .review_images li {
        padding: 15px;
     }
    #all_tours .tour_menu_inner_mobile {
        margin-top: 7px;
        height: auto;
    }
    .landing_title_block,
    .hotel_title_block{
        min-height: 240px;
    }
    .block_title_home h3,
    .block_title_home h1{
        margin-top: 0px;
    }
    #accommadation .accommadation_top .col-lg-4:nth-last-child(1), #accommadation .accommadation_top .col-lg-4:nth-last-child(2), #accommadation .accommadation_top .col-lg-4:nth-last-child(3){
        margin-bottom: 0px;
    }

    .tour_menu .active:after{
        bottom: -2px;
        height: 2px;
    }


    .header_top {
        display: none
    }

    .header_bottom {
        top: 0px;
        background: transparent;
        opacity: 1;
    }

    /*.team .header_bottom, .about .header_bottom, .contact-us .header_bottom, .all-tours .header_bottom, .feedback .header_bottom, .story .header_bottom, .events .header_bottom, .guids .header_bottom {
        background: rgba(4, 2, 20, 0.79)
    }*/

    .logo {
        width: 60%;
        float: left;
        text-align: center;
        margin-left: initial;
    }

    .header_language {
        padding-top: 5px;
    }

    .social_content {
        display: block;
        float: none;
        clear: both;
        padding: 5px 15px;
    }

    .social_content li {
        width: 25px
    }

    .social_content li .facebook {
        background-position: -173px -242px;
    }

    .social_content li .vkontakte {
        background-position:-216px -242px;
    }

    .social_content li .googleplus {
        background-position:-151px -243px;
    }

    .social_content li .e-mail {
        background-position: -237px -242px;
    }

    .social_content li .youtube {
        background-position: -194px -242px;
    }

    .call {
        padding: 5px 15px;
        line-height: 26px;
    }

    .call span {
        color: #333333
    }

    .call .tel span {

        width: 25px;
        height: 25px;
        padding: 3px 0;
        color: #333333
    }
    .call .tel span:before {
        width: 19px;
        height: 19px;
        border-color: #000000;
        background: url('/img/svg/phone_black.svg') no-repeat;
        background-size: 9px;
        background-position: center;
    }


    .currency {
        padding: 5px 28px 5px 5px;
    }
    .disable__scroll .menu{
        margin-top: 5px;
    }
    .menu {
        margin-top: 18px;

    }
 




    .nav > li > a:hover {
        color: #303030 !important;
    }
    .call_mobile {
        display: block !important;
        width: 20%;
        float: left;
        padding-top: 10px;
    }
    .menu {
        width: 20%;
    }
    .menu_inner {
        float: right;
    }
    .navbar-inverse.side-collapse {
        background-color: #f3f3f3;
    }
    .menu .navbar-toggle {
        padding: 14px;
        margin-right: 0;
    }
    .navbar-header {
        float: none;
    }

    .navbar-left, .navbar-right {
        float: none !important;
    }

    .navbar-toggle {
        display: block;
        z-index: 9999;
    }

    .navbar-toggle.menu_open {
        margin-top: 2px;
    }

    .navbar-toggle.menu_open .icon-bar {
        background: #303030;
    }

    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }

    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-nav {
        float: none !important;
        margin-top: 2px;
    }

    .navbar-nav > li {
        float: none;
    }

    .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .dropdown-menu a {
        font-size: 11px !important;
        white-space: normal;
        line-height: 18px;
    }
    .collapse.in {
        display: block !important;
    }

    .side-collapse-container {
        width: 100%;
        position: relative;
        right: 0;
        transition: left .4s;
    }

    .side-collapse-container.out {
        right: 80%;
    }

    .top_menu {
        top: 0px;
        bottom: 0;
        right: 0;
        width: 80%;
        position: fixed;
        overflow-y: auto;
        overflow-x: hidden;
        transition: width .1s;
        z-index: 99;
    }
    .navbar-inverse .social_content ul,
    .navbar-inverse .social_content ul li:first-child {
        margin-left: 0;
    }
    .top_menu.in {
        width: 0;
    }

    .new_carrency .nav.navbar-nav li:last-child a {
        padding-right: 25px;
    }

    #new_navbar li a.dropdown-toggle .dropdown_arrow:after {
        color: #303030;
        top: 7px;
    }

    #new_navbar li a.dropdown-toggle .dropdown_arrow:after {
        right: 5px;
    }

    #new_navbar .dropdown .dropdown_arrow:after {
        right: 10px;
        top: 25%;
    }

    #navbar {
        border-top-color: #c2c2c2;
    }

    #new_navbar {
        border-top: 0px;
        padding-left: 0px
    }

    #navbar li {
        width: 100%;
        background: #ebebeb;
        margin: 3px 0;
        clear: both
    }

    #new_navbar li {
        width: 100%;
    }

    #navbar li:hover {
        background: #e1e1e1
    }

    #navbar li a {
        border: none;
        color: #303030;
    }

    #new_navbar li a.dropdown-toggle {
        background: transparent;
        padding-left: 0;
        cursor: pointer
    }

    #new_navbar li a {
        border: none;
        color: #303030;
    }

    .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
        background: #e1e1e1
    }

    .dropdown .dropdown_arrow:after {
        right: 30px;
        top: 12px;
    }

    .call_mobile .tel span {
        width: 30px;
        height: 30px;
        display: block;
        /* margin-left: 15px; */
        background: url('/img/svg/phone_white.svg') no-repeat;
        background-size: 16px;
        background-position: center;
        margin-top: 10px;
        border: 2px solid #ffffff;
        border-radius: 50%;
    }
    .header_bottom_inner a.tel{
        display: block;
    }

    .header_language {
        float: left
    }

    .currency .active:after {
        background-position: -235px -179px;
    }

    .header_language li a {
        border-right: 1px solid #303030;
    }

    .arara_buttons {
        margin-top: 15px;
    }

    .btn_reviews, .more_about_arara {
        font-size: 18px;
        padding: 10px;
        padding-top: 15px;
        margin-bottom: 30px;
    }

    .adventur_sliders_title {
        font-size: 14px;
        line-height: 20px;
        padding: 6px 0 2px 5px;
    }
    .adventure_block_arrows {
        top: 1px;
    }
    .tour_menu_book_now {
        margin-left: 15px;
    }

    .tour_menu_book_now .book_now {
        font-size: 16px;
        padding: 2px 20px;
        margin-top: 3px;
        min-width: 0;
    }

    .call .tel {
        color: #333333
    }

    .social_content li {
        height: 35px;
    }

    .book_now {
        padding-top: 5px;
    }

    .navbar-inverse.side-collapse #new_navbar .dropdown-menu {
        position: absolute;
        border-radius: 4px;
        background: #d1d0d0;
        top: 30px;
        left: -5px;
    }

    .tour_book_now {
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .tour_menu li a {
        font-size: 14px;
    }

    /*.tour_accordions .download_brochure {
        top: 15px
    }*/

    .tour_accordions .first .expand_all {
        top: 0;
       /* z-index: 999999;*/
        z-index: 1;
    }

    button.navbar-toggle.pull-left.menu_open {
        position: fixed;
        right: 0;
        width: 40px;
        padding: 15px;
    }

    #new_navbar .dropdown-menu a {
        padding: 3px 5px;
    }

    .dropdown.last .dropdown_arrow:after {
        right: 30px
    }
    .header_bottom_inner {
        padding: 0 45px;
    }
    .header_bottom_inner:before {
        left: 45px;
        right: 45px;
        bottom: -1px;
        height: 1px;
    }
    .carousel_navbar {
        bottom: 95px;
    }
    .navbar_container:before {
        top: auto;
        bottom: 0;
        left: 30px;
        right: 30px;
        height: 1px;
    }
    .carousel_navbar ul {
        text-align: center;
        font-size: 0;
        margin: 0;
        width: 100%;
    }
    .carousel_navbar ul:after,
    .go_to_next {
        display: none
    }
    .carousel_navbar ul li {
        float: none;
        width: auto;
        margin: 0 10px 94px;
        display: inline-block;
        vertical-align: top;
    }
    .carousel_navbar ul a {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
        background: #fff;
        opacity: 0.38;
    }
    .carousel_navbar ul a span,
    .carousel_navbar ul:before{
        display: none !important;
    }
    .carousel_navbar ul a.active {
        opacity: 1 !important;
    }
    .custom_pagination div a {
        line-height: 26px;
        padding-top: 2px;
    }
    .tour_second_accordions .first .left li:before {
        margin-top: -1px;
    }
    .tour_second_accordions .first .right li:before{
        margin-top: -2px;
    }
}

@media (max-width: 992px) {
    .team_members_parent .team_members, .about_our_guides_parent .guides {
        width: 33.33%;
    }
    .member_name {
        padding-top: 10px;
        padding-bottom: 0;
    }
    .last_minute {
        font-size: 12px;
        padding: 5px
    }

    .book_now {
        font-size: 17px;
    }

    .travelers_items .fa-star {
        font-size: 15px;
        padding-left: 0px;
    }

    .reviews {
        font-size: 17px;
        margin-top: 0;
    }
   /* .tour_accordions .accardion_header .title{
        font-size: 17px;
    }*/
    .individual, .any_date, .tour_duration, .BB {
        font-size: 12px;
    }


    .price_block .price, .price_block .sale_price {
        font-size: 16px;
    }

    .book_with_Arara .col-lg-4.col-md-4 {
        padding: 0px 15px;
    }

    .book_with_Arara .top, .book_with_Arara .bottom {
        margin: 10px 0 10px 75px;
        font-size: 12px;
    }

   /* #custom_carousel .slide_title, .tour_title_block .tour_title {
        font-size: 30px;
    }
*/
    #custom_carousel .carousel-caption .slide_text {
        font-size: 17px;
        line-height: 25px;
    }

    .tour_details .duration, .tour_details .age, .tour_details .tour_date, .tour_details .classic_tour, .tour_details .small_group, .tour_details .tour_price_block, .tour_details .BB {
        padding-left: 47px;
        line-height: 30px;
        font-size: 12px;
    }

    .tour_menu li {
        margin-right: 10px;
    }

    .tour_menu_book_now {
        width: 15%;
    }

    .tour_menu_inner_mobile::-webkit-scrollbar {
        width: 0px;
    }

    .tour_menu li {
        display: inline-block;
        float: none;
    }



    .guides ul li:before {
        height: 20px
    }

    .guides ul li a {
        line-height: 24px;
        font-size: 14px;
    }

    .similar_tours_title {
        padding: 0 15px;
    }

    .legal_information img {
        max-width: 100%
    }

    #feedback .feedback_right_block .feedback_ratig_left {
        width: auto;
        padding-bottom: 5px;
        display: inline-block
    }

    #feedback .feedback_right_block .feedback_ratig_left .feedback_ratig_left_text {
        font-size: 15px;
    }

    #feedback .feedback_right_block .feedback_ratig_right .fa-star {
        font-size: 18px
    }

    #feedback .feedback_right_block .feedback_ratig_right {
        display: inline-block;
        float: right
    }

    #feedback .feedback_right_block {
        padding-bottom: 10px;
    }

    .stoty_bottom .similar_tours {
        padding-bottom: 0;
    }

    #story .stoty_bottom .recent_posts_title {
        margin-top: 0;
    }

    #all_tours .tour_menu_inner {
        max-width: 750px;
    }

    .book_with_Arara .col-lg-4 div:before {
        left: -55px;
    }
    /*#book_arara .book_with_Arara .col-lg-4 div:before {
        left: -52px;
    }*/

    /*.book_with_Arara .col-lg-4:first-child div {
        margin: 10px 0 10px 45px;
    }*/

    .tour_menu_book_now .book_now {
        font-size: 15px;
        padding: 2px 5px;
    }

    .stoty_bottom .similar_tours .similar_tours_title {
        padding: 0px;
    }

    .tour_menu_inner_mobile {
        overflow-x: auto;
    }
    /*.tour_details .duration:before,
    .tour_details .tour_date:before{
        top: 5px;
    }
    .tour_details .small_group:before,
    .tour_details .tour_price_block:before{
        top: 12px;
    }
    .tour_details .age:before {
        top: 15px;
    }*/
    .travelers_items h4 {
        height: 40px;
        line-height: 20px;
    }


}
@media (min-width: 768px) {
    .landing_prelude,
    .epilogue_inner{
        height: auto !important;
    }
    .more_less,
    .more_less2{
        display: none !important;
    }
}
@media (max-width: 768px) {
    .tour_accordions .accardion_item .custom_accordion:after {
        left: -30px;
    }
    #similar_tours .col-xs-12:last-child {
        margin-bottom: 0px;
    }
    .footer_top_inner .col-lg-3 {
        width: 50%;
    }




     .usefull_information .col-sm-4:first-child {
        margin-top: 0px;
    }


    .book_with_Arara .top:before, .book_with_Arara .bottom:before {
        left: 15px;
    }

    .individual, .any_date, .tour_duration, .BB {
        line-height: 22px;
    }

    .btn_reviews, .more_about_arara {
        margin: 20px 10px 10px;
        font-size: 15px;
    }
    .arara_buttons {
        margin-top: 0;
        padding-top: 0;
    }

    .tour_menu li {
        line-height: 35px;
    }
    .tour_menu_inner_mobile {
        width: 70%
    }

    .tour_menu_book_now {
        width: 25%;
    }

    /*.tour_banner{overflow:hidden; min-height:640px; padding-bottom:0}*/
    /*.tour_banner img{position: relative;max-width: fit-content;width:auto;top: 0%;left: -50%; bottom: 0;}*/
    .tour_details .left {
        padding-left: 15px;
    }

    .tour_title_block {
        top: 30%
    }



    /*.tour_accordions .download_brochure {
        font-size: 16px
    }*/

    .tour_accordions .accardion_item {
        padding: 0px 0px 15px 30px;
    }
    .tour_accordions .active.accardion_item.first .custom_accordion_content {
        padding-bottom: 5px;
    }
    .tour_second_accordions .first .left {
        border: none
    }

    .similar_tours .col-sm-4{
        margin-bottom: 30px
    }

    .similar_tours .col-sm-4:last-child {
        margin-bottom: 0px
    }

    .tours_first_block .right {
        padding-left: 15px;
    }

    .book_with_Arara .top, .book_with_Arara .bottom {
        margin: 0px 0 10px 60px;
    }

    .current_tour_reviews {
        padding-bottom: 0;
    }

    .current_tour_reviews .fa-star {
        color: #397fba;
        font-size: 18px;
        padding-left: 1px;
    }

    .team_members_parent .team_members, .about_our_guides_parent .guides {
        width: 50%;
    }


    .member_position {
        min-height: auto;
        padding: 12px 20px;
    }

    #arara_team h6 {
        font-size: 15px;
    }

    #contact_us .contact_us_content .right {
        padding-left: 0px
    }

    #contact_us h6 {
        font-size: 16px
    }

    #contact_us .contact_us_content h6 {
        font-size: 16px;
    }

    #contact_us {
        font-size: 16px
    }

    #contact_us .contact_us_form .form_submit {
        clear: both;
        margin-top: 0;
    }

    #feedback .feedback_bottom .left .inner_left.col-lg-6 {
        padding-right: 0px;
    }

    #feedback .feedback_bottom .right {
        padding: 0;
    }

    #events .events_bottom_content .left.col-sm-6 {
        padding-left: 15px;
        margin-bottom: 35px;
    }

    #events .events_bottom_content .right {
        padding-right: 15px;
    }

    #events #events_form .inner_right {
        padding: 0px;
    }

    .footer_top_inner img {
        width: 100%
    }


    .book_with_Arara .col-lg-4 div, .book_with_Arara .col-lg-4:first-child div {
        margin: 10px 0 10px 55px;
    }

    .social_content li {
        width: 23px;
        height: 35px;
        margin: 0 10px 0 0 !important;
    }

    .btn {
        padding: 5px 15px;
    }

    .book_now {
        font-size: 15px;
    }

    #guaranteed_tour_dates .book_now {
        font-size: 18px;
        padding-top: 7px;
    }

    #guaranteed_tour_dates .table-responsive {
        display: none
    }

    #guaranteed_tour_dates #guaranteed_tour_dates_mobile {
        display: block
    }

    .tour_menu ul {
        margin-top: 5px;
    }

    .events_form_parent .form_submit {
        clear: both;
        padding-top: 15px;
    }



    #story .stoty_bottom .recent_posts .col-md-4 {
        padding-bottom: 30px;
    }

    #story .stoty_bottom .recent_posts .col-md-4.last {
        padding-bottom: 0px;
    }

    #about_parent {
        margin-bottom: 0px;
    }

    #story .col-lg-4 {
        margin-bottom: 30px;
    }

    .similar_tours_title {
        padding: 0px;
    }

    .single_tour_details span:before {
        top: 0px;
    }

    .tour_dates_mobile_price h4 {
        line-height: 22px
    }

    #feedback .feedback_bottom .left {
        padding-right: 0px
    }

    #feedback .feedback_right_block .feedback_ratig_left {
        width: auto;
    }

    #feedback .feedback_bottom .row:last-child .left {
        padding-right: 15px;
    }

    #feedback .feedback_bottom .row:last-child .right {
        padding-right: 0px
    }

    #new_navbar li a.dropdown-toggle .dropdown_arrow:after {
        top: 8px;
    }

    .navbar-nav {
        margin-top: 7px
    }

    .row.about_our_guides_parent {
        margin: 0px;
    }


    .legal_information h3 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .age, .BB, .duration, .small_group, .tour_date {
        font-size: 13px
    }



    .usefull_information .travelers_items .bottom.news_hover {
        top: 15%
    }

    .news_hover .usefull_text {
        min-height: 175px;
        max-height: 175px;
        overflow: hidden
    }

    #story .col-lg-4:last-child {
        margin-bottom: 0
    }



    #story h1 {
        margin-left: 0px !important;
    }

    #story .custom_pagination .custom_pagination_inner {
        padding-bottom: 0px !important;
    }

    #story.hotels_inner_page .story_bottom_text {
        padding: 0 15px;
    }

    .review_images li {
         width: 50%;
     }
}

@media (min-width: 767px ){
    .modal_bottom .customize_img {
        background-position: -61px -290px;
        margin-left: 42px;
    }
    .modal_bottom .payment_img {
        background-position: -104px -290px;
    }
}
@media (min-width: 667px) {
    .modal_bottom .customize_img {
        background-position: -61px -290px;
        background-color: #fff;
    }
    .modal_bottom .payment_img {
        background-position: -105px -290px;
    }
}
@media (max-width: 767px) {

    #custom_carousel .carousel-control {
        display: none
    }
    .two_columns > li,
    .two_columns > div {
        font-size: 16px;
    }
    /*#custom_carousel .item {min-height: 680px;height:100%}*/
    #custom_carousel .item img {
        position: relative;
        max-width: fit-content;
        width: auto;
        top: 0%;
        left: -50%;
        bottom: 0;
    }

    #custom_carousel .carousel-caption {
        right: 15px;
        left: 15px;
        bottom: 0;
        top: 30%;
    }

    #custom_carousel .carousel-caption .btn {
        margin-left: 0px
    }

    .adventur_sliders .col-sm-4, .usefull_information .col-sm-4 {
        margin-top: 30px
    }

    .all_tours_row .col-lg-4 {
        margin-bottom: 30px;
    }

    .all_tours_row .col-lg-4:last-child {
        margin-bottom: 0px;
    }



    #arara_team .arara_team_members .last.col-sm-4 {
        margin-bottom: 0px
    }
    .logo img {
        width: auto;
        height: 42px;
    }
    .header_bottom_inner {
        padding: 0 12px;
    }
    .logo {
        padding: 13px 0;
    }
    .header_bottom_inner:before {
        left: 12px;
        height: 1px;
        right: 12px;
        bottom: -1px;
    }
    .carousel_navbar {
        bottom: 30px;
    }
    .navbar_container:before {
        bottom: 0;
        left: 12px;
        right: 12px;
        height: 1px;
    }
    .carousel_navbar ul li {
        margin: 0 5px 30px;
    }
    .carousel_navbar ul a {
        width: 11px;
        height: 11px;
    }
    /*#custom_carousel .slide_title {
        font-size: 25px;
        line-height: 28px;
        padding: 0 15px;
    }*/
    #custom_carousel .slide_title:after {
        margin: 15px 0 0;
        max-width: 65%;
    }
    #custom_carousel .slide_text,
    #custom_carousel a.slider_button{
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        padding: 0 15px;
    }
    .slide_info_block {
        top: 68px;
    }
    .about_tour_book {
        position: relative;
        top: auto;
        right: auto;
        max-width: none;
        border: none;
        border-left: 2px solid #f3f3f3;
        border-radius: 0;
        background: transparent;
        z-index: 2;
        font-size: 11px;
        color: #787c7f;
        line-height: 18px;
        padding: 7px;
    }
    .dayly_slider {
        margin-bottom: 10px;
    }
    .dayly_slider .slider_description {
        font-size: 10px;
        line-height: 12px;
        padding-top: 10px;
    }
    #itinerary .accardion_item .custom_accordion {
        line-height: 20px;
        padding-right: 35px;
        margin-top: 15px;
    }
    #itinerary .accardion_item .custom_accordion:before {
        width: 8px;
        height: 8px;
        margin-top: -4px;
        background-size: 12px;
    }
    .expand_all:after {
        margin-left: 10px;
    }
    #itinerary .accardion_item:after {
        left: 33px;
        right: 7px;
    }
    #itinerary .accardion_item .custom_accordion:after {
        top: -2px;
    }
    #itinerary .accardion_item.active .custom_accordion:after {
        top: -3px;
    }
    #itinerary .accardion_item.second .custom_accordion:after {
        top: -1px;
    }
    #itinerary .accardion_item.active.second .custom_accordion:after {
        top: -4px;
    }
    .tour_accordions .download_brochure {
        width: 100%;
        font-size: 12px;
        background: transparent;
        color: #2f7dc9;
        border: 1px solid #2f7dc9;
        margin-top: 20px;
        padding: 4px 15px;
        line-height: 16px;
        text-align: center;
    }
    #itinerary {
        padding-bottom: 20px;
    }
    .hotel_title_block {
        padding-top: 100px;
        padding-left: 5px;
        padding-right: 5px;
        min-height: 0;
    }
    .hotel_title_block:before {
        display: none;
    }
    .hotel_name {
        font-size: 30px;
        line-height: 48px;
        padding: 0 11px;
    }

    .big_images .slick-arrow,
    .bike_images .slick-arrow{
        width: 20px;
        height: 40px;
        margin-top: -20px;
        background-size: 40px;
    }
    .big_images .slick-prev,
    .bike_images .slick-prev{
        left: 30px;
    }
    .big_images .slick-next,
    .bike_images .slick-next{
        right: 30px;
    }
    .small_images {
        margin-top: 16px;
    }
    .small_images .image_block {
        padding-right: 1px;
        padding-left: 0;
    }
     .details_subtitle,
     .about_who_are h3,
     .about_offer h3,
     .about_our_mission h3,
     .about_our_guides_parent h3,
     .about_our_team h3,
     .legal_information h3{
        font-size: 24px;
        line-height: 29px;
        padding: 0 0 0 23px;
        border-left-width: 3px;
        margin-bottom: 30px;
    }
    .details_list ul.two_col_list {
        -moz-column-gap: 25px;
        -webkit-column-gap: 25px;
        column-gap: 25px;
    }
    ul.two_col_list {
        -moz-column-gap: 25px;
        -webkit-column-gap: 25px;
        column-gap: 25px;
    }
    .details_list ul {
        font-size: 15px;
        line-height: 27px;
        padding-bottom: 15px;
    }
    .details_list ul li,
    .about_offer_content{
        padding-left: 26px;
        padding-bottom: 10px;
    }

    .landing_title_block {
        padding-top: 100px;
        padding-left: 5px;
        padding-right: 5px;
        min-height: 0;
    }
    .landing_title {
        font-size: 30px;
        line-height: 30px;
    }
    .landing_prelude {
        margin-top: 15px;
        font-size: 15px;
        max-height: 120px;
    }
    .more_less {
        color: inherit;
        font-size: 15px;
        line-height: 24px;
    }
    .more_less .close_text,
    .more_less2 .close_text,
    .epilogue_inner.showed + .more_less2 .open_text,
    .landing_prelude.showed + .more_less .open_text {
        display: none
    }
    .epilogue_inner.showed + .more_less2 .close_text,
    .landing_prelude.showed + .more_less .close_text {
        display: block
    }
    .adventur_sliders {
        margin-top: 0;
    }
    .usefull_information .travelers_items .bottom {
        height: auto !important;
        max-height: 205px;
    }

    .epilogue_decor {
        height: 35px;
    }
    .epilogue_inner {
        font-size: 15px;
        max-height: 96px;
    }
    .more_less2 {
        font-size: 13px;
        line-height: 24px;
    }
    .tour_details {
        padding: 20px 0 5px;
    }
    .tour_book_now {
        margin: 0;
    }
    .tour_details .col-xs-12.right {
        padding: 0 10px;
    }
    .tour_details .duration, .tour_details .age, .tour_details .tour_date, .tour_details .classic_tour, .tour_details .small_group, .tour_details .tour_price_block, .tour_details .BB {
        padding-top: 0;
        padding-bottom: 15px;
        font-size: 13px;
        line-height: 14px;
        padding-left: 33px;
    }
    .tour_details .tour_price_block {
        padding-bottom: 10px;
    }
    .tour_details .sale_price,
    .tour_price_block > span:first-child{
        margin-bottom: 5px;
    }



    .tour_details .tour_sprite:first-child {
        margin-top: 0;
    }
    .tour_details .left {
        padding-left: 5px;
    }
    .tour_details .book_now {
        position: absolute;
        left: 15px;
        right: 15px;
        border: none;
        color: #ffffff;
        background: #2f7dc9;
        font-size: 14px;
        line-height: 12px;
        bottom: -55px;
        padding: 9px;
        width: auto;
    }
    .tour_banner_bottom .banner_review {
        position: absolute;
        top: -36px;
        border-top: 1px solid rgba(255,255,255,0.4);

    }
    .banner_review .reviews_block {
        padding: 8px 0;
    }
    .banner_review .reviews_block .stars  .fa-star {
        font-size: 15px;
    }
    .banner_review .reviews {
        font-size: 14px;
        line-height: 20px;
    }
    /*.tour_accordions .accardion_header .title{
        font-size: 14px;
    }*/
    #itinerary .accardion_item {
        padding-bottom: 15px;
    }
    .menu .dropdown-menu a {
        font-size: 13px !important;
    }
    .tour_banner {
        margin-bottom: 70px;
        min-height: 100vh;
    }
    .tour_price_block > span {
        padding-bottom: 1px;
        float: left;
    }
    .tour_price_block .sale_price {
        display: inline-block;
        padding-right: 10px;
    }
    .tour_price_block .price {
        padding: 0;
    }
    #navbar li a {
        white-space: normal;
    }

}

@media (max-width: 650px) {
    .about_our_guides_parent .guides {
        min-height: 400px;
    }

    .team_members_parent .team_members, .about_our_guides_parent .guides {
        width: 50%;
    }

    .booking_modal_container .modal-dialog {
        width: auto;
        margin: 15px;
    }
    .booking_modal_container .booking_form .form-group,
    .booking_modal_container .booking_form .form-group, .modal_text{
        width: 100%;
    }

    .booking_modal_container .booking_form .form_submit {
        padding-bottom: 30px;
    }

    .booking_modal_container .custom-modal-content .modal-header .modal-title {
        font-size: 16px;
    }

    #story .story_bottom_text img {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 15px;
    }

    #story .story_bottom_text {
        padding-top: 15px;
    }
    #story .story_top .story_bottom_text:first-child {
        padding-top: 0;
    }
    .traveller_information_text, .payment_text {
        height: 30px;
    }

    .payment_text {
        line-height: 30px;
    }

    .modal_bottom .customize, .modal_bottom .confirmation {
        padding-top: 30px;
    }

    .modal_bottom li:after {
        top: 55px;
    }

    #new_navbar li a {
        font-size: 15px;
    }

    #new_navbar .navbar-nav {
        margin-bottom: 0px
    }



    #story.hotels_inner_page .story_bottom_text {
        padding: 0;
    }

    #story.hotels_inner_page .story_bottom_text .story_bottom_text_inner {
        padding: 0 15px;
    }
}

@media (max-width: 560px) {


    .about_our_guides_parent .guides {
        min-height: 350px;
    }

}

@media (max-width: 480px) {
    .review_images li {
        width: 100%;
        padding-bottom: 7px;
        margin: 0;
    }
    .arara_team_members .col-sm-4{
        width: 100%;
    }
    #arara_team .member_profesion .text{
        min-height: 0;
    }
    #trip_map .col-sm-6:last-child {
        margin-bottom:0px;
    }
    .tour_second_accordions .first .left{
        margin-bottom: 30px;
    }
    .tour_accordions .accardion_item .custom_accordion:after{
        top:-4px;
    }
    .tour_accordions .accardion_item:before{
        top: 7px;
    }


    #book_arara .btn_reviews{
        margin-bottom: 15px;
        margin-top: 20px;
    }
    #book_arara .more_about_arara {
        margin-bottom: 0px;
    }
    #book_arara .arara_buttons{
        margin: 0;
    }
    .current_tour_reviews .stars {
        float: none;
        padding: 2px 0 10px 0;
        display: block;
        text-align: right;

    }
    .tour_menu_inner_mobile_inner{
        top:0;
    }
    .tour_title_block .tour_subtitle {
        font-size: 17px;
    }

    /*.menu {
        width: 15%
    }*/

    .call_mobile {
        padding-top: 10px;
    }

    .call, .social_content {
        padding: 5px 10px;
    }

    .header_language li a {
        padding: 0px 10px;
    }

   /* .logo {
        width: 75%;
    }
*/
    #feedback .form-group.form_submit {
        padding: 0px
    }
    .disable__scroll .menu .navbar-toggle {
        margin-top: 0px;
    }
    .menu .navbar-toggle {
        margin-top: 6px;
    }

    .block_title h3,
    .block_title_home h1{
        text-align: center;
        letter-spacing: 1px;
    }



    .footer_top_inner.top li a {
        display: inline-block;
        padding-left: 20px;
        position: relative;
        font-size: 12px;
    }

    .footer_top_inner.top {
        padding-bottom: 0
    }

    .footer_top_inner {
        padding-top: 25px;
    }

    .footer_top_inner img {
        padding-top: 20px;
    }
    .tour_menu_inner_mobile {
        width: 65%;
    }
    .tour_menu_book_now {
        width: 29%;
    }
    .tour_menu_book_now .book_now {
        font-size: 12px;
        padding: 2px 3px;
    }

    .tour_menu_inner_mobile {
        margin-top: 8px
    }



   /* .tour_accordions .download_brochure {
        font-size: 12px;
        padding: 0 7px;
        top: 20px;
        height: 20px;
        line-height: 23px;
    }*/

    .tour_second_accordions .custom_accordion,
    .tour_accordions .accardion_header .title{
        font-size: 16px
    }
    .tour_second_accordions .custom_accordion_content .stars {
        width: 100%;
        /*text-align: right;*/
        /*padding-bottom: 25px;*/
    }

    /*#book_arara .book_with_Arara .bottom:before, #book_arara .book_with_Arara .top:before {
        left: 0px;
    }*/

    .book_with_Arara .top, .book_with_Arara .bottom {
        margin: 0px 0 10px 45px;
    }

    .btn_reviews, .more_about_arara {
        max-width: 250px;
    }

    .copyright {
        font-size: 12px;
        padding: 25px 10px;
    }

    #guaranteed_tour_dates table h4, #guaranteed_tour_dates th {
        font-size: 13px
    }

    #guaranteed_tour_dates span {
        font-size: 12px;
    }

    #guaranteed_tour_dates .book_now {
        letter-spacing: 0px;
        font-size: 14px;
    }

    .accardion_title, .accommadation .hotel_star, .tour_accordions .custom_accordion {
        font-size: 16px;
    }
    .article_feedbacks,
    .tour_second_accordions .first li, .tour_reviews_text, .accommadation_text, .packing_checklist_item .packing_checklist_item_text,
    .current_day_description, .tour_accordions .accardion_item.first .custom_accordion_content,
    .guide_reviews a, .legal_information div{
        font-size: 16px;
        line-height: 27px;
    }

    .team_members_parent .team_members, .about_our_guides_parent .guides {
        width: 100%;
    }

    .about_our_guides_parent .guides {
        min-height: auto;
    }



    .book_with_Arara .top:before, .book_with_Arara .bottom:before {
        left: 5px;
    }

    #contact_us .contact_us_form {
        width: 100%;
    }

    #contact_us, #contact_us .contact_us_form label {
        font-size: 16px
    }

    .custom_pagination {
        max-width: 300px;
    }

    .custom_pagination_inner div {
        width: 25px;
        height: 25px;
        margin: 0 3px;
    }
    .custom_pagination div a {
        line-height: 21px;
    }
    .booking_modal_container .modal {
        top: 0%;
    }

    .booking_modal_container .modal-dialog {
        margin: 15px;
    }

    .booking_modal_container .booking_form .form-group label {
        line-height: 20px;
        margin-bottom: 0;
    }

    #events h1 {
        font-size: 17px;
    }

    #events h3 {
        font-size: 14px;
    }

    #events .events_bottom_content h2, #events .bottom_container h2 {
        font-size: 15px;
    }



    .currency {
        padding: 0px 28px 0px 5px;
    }

    .tour_menu li a {
        font-size: 13px;
    }

    .load_more_reviews a {
        font-size: 14px;
        line-height: 24px;
    }
    .load_more_reviews a:before {
        height: 8px;
        background-size: auto 8px;
    }

        /*.load_more_reviews a:before {*/
    /*left: -30px;*/
    /*background-position: -72px -157px;*/
    /*background-size: 220px;*/
    /*}*/


    .modal_text {
        width: 100%;
        margin-top: 15px !important
    }





    .team_members.last {
        margin-bottom: 0px
    }

    .guides ul li:before {
        top: 3px;
    }

    #new_navbar {
        padding-left: 15px;
    }

    #new_navbar li {
        margin: 0px;
    }

    #new_navbar .dropdown .dropdown_arrow:after {
        top: 20%;
    }

    #new_navbar li a.dropdown-toggle {
        line-height: 25px;
        padding-top: 8px;
    }

    .tour_menu li {
        line-height: 25px;
    }

    .tour_details .col-lg-4 {
        padding-left: 0px !important;
        padding-right: 0;
        clear: both;
        padding-top: 5px
    }

    .tour_duration:before {
        background-position: -45px -47px;
    }


    .row.about_our_guides_parent .our_guides {
        margin: 0px
    }

    .navbar-nav {
        margin-top: 3px;
    }

    #story .story_bottom_text .story_bottom_text_inner {
        width: 100%;
        float: none;
        clear: both
    }


    .custom_pagination div i {
        font-size: 16px;
    }

    #story.story_inner_page .story_bottom_text table {
        width: 100% !important;
    }

    #story.story_inner_page .story_bottom_text img {
        width: 100% !important;
    }

    #new_navbar li a.dropdown-toggle .dropdown_arrow:after {
        top: 9px;
    }


    .tour_second_accordions .first .left li:before {
        margin-top: -2px;
    }
    .tour_second_accordions .first .right li:before{
        margin-top: -3px;
    }
    .popup_close {
        background: url('/img/svg/cancel_black.svg') no-repeat;
        background-size: 24px;
        background-position: center;
        top: 0;
        right: 0;
    }

    .tour_dates_mobile_inner .top_row .col-xs-5 span.guaranteed_tour {
        font-size: 12px !important;
        white-space: nowrap;
    }
    .tour_reviews_text {
        max-height: 108px;
    }
}


@media (max-width: 413px) {
    #story .item-description{
        min-height: auto;
    }

    .footer_top_inner.top li a {
        padding-left: 15px;
    }



    #feedback .feedback_right_block .feedback_ratig_left .feedback_ratig_left_text {
        font-size: 13px;
    }

    #feedback .feedback_right_block .feedback_ratig_right .fa-star {
        font-size: 14px;
        padding-left: 0px;
    }
    #feedback .feedback_right_block .feedback_ratig_right .br-theme-fontawesome-stars .br-widget a {
        width: 18px;
        height: 18px;
    }
    .payment-amount {
        max-width: 150px;
    }

}

@media (max-width: 370px) {
    .call {
        font-size: 12px;
    }

  /*  .logo {
        width: 75%;
    }*/

    .navbar-inverse.side-collapse .header_top_inner_right {
        padding: 0 5px;
    }

    .header_language li a {
        padding: 0 8px;
    }

    .currency {
        padding: 5px 22px 5px 5px;
    }

    .currency.curr_open .active:after {
        top: 17px;
        right: -3px
    }

    .currency li {
        font-size: 14px
    }

    .book_with_Arara .top:before, .book_with_Arara .bottom:before {
        left: 0px;
    }

    .booking_modal_container .custom-modal-content .modal-header .modal-title {
        font-size: 14px;
    }

    .booking_modal_container .booking_form .form-group label {
        padding-right: 5px;
        font-size: 12px;
    }


    .footer_top_inner.top li a {
        font-size: 10px;
    }
    .tour_details .left {
        padding-left: 0;
    }
    .first_step_block {
        padding: 15px;
    }
    .field_block {
        padding: 0 24px;
    }


    /*.booking_modal_container .modal{height:615px;}*/
}

@media (min-width: 1024px) {
    .news_hover .usefull_text {
        height: 244px;
        overflow: hidden;
    }

}
@media (max-width: 1024px) {
    #custom_carousel .slide_title, .tour_title_block .tour_title {
        font-size: 60px;
        line-height: 60px;
    }
    .payment_currency,
    .payment-amount {
        line-height: 28px;
        padding-top: 2px;
    }
    .step_number {
        padding-top: 8px;
        padding-bottom: 6px;
    }
    .tour_second_accordions .custom_accordion {
        line-height: 18px;
        padding-top: 17px;
    }
    .tour_accordions .accardion_header .title {
        line-height: 28px;
        padding-top: 2px;
    }
    .story_bottom_text img {
        max-width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
@media screen and (max-width: 767px) {
    #trip_map .col-sm-6:first-child {
        margin-bottom: 30px;
    }
    .epilogue_title {
        padding: 4px 15px 0;
        font-size: 30px;
        line-height: 33px;
    }
    .col-lg-6.right > .details_subtitle:first-child {
        margin-top: 25px;
    }
    .payment_success .popup_close {
        background: url('/img/svg/cancel_black.svg') no-repeat;
        background-size: 24px;
        background-position: center;
        top: 0;
        right: 0;
    }
    .success_info {
        font-size: 17px;
        line-height: 28px;
        padding: 20px 0 20px 80px;
    }
    .tours_first_block {
        padding-bottom: 5px;
        padding-top: 0;
    }
    .story_bottom_text img {
        margin: 10px 0 !important;
    }
    #custom_carousel .slide_title, .tour_title_block .tour_title {
        font-size: 32px;
        line-height: 32px;
    }
    .tour_dates_mobile_inner .bottom_row .spaces_left span,
    .tour_dates_mobile_inner.nodate_tour  .top_row .col-xs-5 .guaranteed_dates,
    .tour_dates_mobile_inner.nodate_tour  .top_row .col-xs-5 h4 {
        font-size: 16px !important;
    }
    .rating_block {
        width: 85px;
        height: 17px;
        margin-top: 2px;
    }
    .rating_block span {
        background-size: 17px !important;
    }
}
@media screen and (max-width: 479px) {
    .success_info {
        font-size: 14px;
        line-height: 21px;
    }
}

@media screen and (max-width: 1024px) {
    #arara_team .member_profesion .text {
        height: 160px;
    }
    .header_menu_block {
        position: fixed;
        top: 0;
        right: 0;
        width: 0;
        height: 100%;
        overflow: hidden;
        z-index: 100;
        -webkit-transition: width 0.3s;
        -moz-transition: width 0.3s;
        -ms-transition: width 0.3s;
        -o-transition: width 0.3s;
        transition: width 0.3s;
    }
    .header_menu_block:before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        left: 120px;
        height: 100%;
        display: block;
        background: #f3f3f3;
    }
    .header_menu_inner {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
        z-index: 10;
        float: right;
        background: #f3f3f3;
        margin-left: 120px;
    }
    html.menu_opened {
        position: relative;
        height: 100%;
    }
    body.menu_opened {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .menu_opened .header_menu_block {
        width: 100%;
    }
    .header:before,
    .submenu_list li:after,
    .submenu_list li:first-child:before{
        display: none;
    }
    .main_logo {
        position: relative;
        top: auto;
        left: auto;
        padding: 7px 0;
        text-align: center;
    }
    .header {
        border: none;
    }
    .header_buttons {
        margin: 0 30px;
        border-bottom: 1px solid rgba(255,255,255,0.4);
    }
    .phone_mob {
        display: block;
        width: 30px;
        height: 30px;
        margin-top: 19px;
        background: url('/img/svg/phone_white.svg') no-repeat;
        background-size: 30px;
        z-index: 20;
        position: relative;
        margin-right: 11px;
    }
    .menu_button {
        display: block;
        width: 52px;
        position: relative;
        padding: 21px 10px 21px 20px;
        margin-top: 3px;
        z-index: 20;
        margin-right: -10px;
    }
    .menu_button:before,
    .menu_button:after {
        display: block;
        height: 2px;
        background: #ffffff;
        content: '';
    }
    .menu_button span {
        display: block;
        height: 2px;
        background: #ffffff;
        margin: 6px 0;
    }
    .header_top_left {
        position:relative;
        top: auto;
        left: auto;
        color: #333333;
    }
    .menu_close {
        position: absolute;
        top: 3px;
        right: 0;
        width: 40px;
        height: 40px;
        background: url('/img/svg/close.svg') no-repeat;
        background-size: 14px;
        background-position: center;
        z-index: 15;
    }
    .languages {
        padding: 12px 4px;
        text-align: left;
        border-bottom: 1px solid #c2c2c2;
        height: 47px;
    }
    .languages ul li {
        margin: 0;
        padding: 0;
        border-color: #303030;
    }
    .languages ul li:first-child {
        border: none;
    }
    .languages a {
        font-size: 0;
        padding: 0 10px;
        line-height: 0;
    }
    .languages a:before {
        color: #303030;
        content: attr(data-mobile);
        display: block;
        font-size: 15px;
        line-height: 22px;
    }
    .languages li.active a:before {
        color: inherit;
    }
    .main_menu {
        padding-top: 0;
    }
    .menu_item {
        margin-left: 0;
        margin-top: 3px;
        background: #ebebeb;
        width: 100%;
        white-space: normal;
        padding: 0;
       
    }
    .menu_item > a {
        font-size: 16px;
        line-height: 20px;
        padding: 10px 14px;
        color: #303030 !important;
        opacity: 1 !important;
        
    }
    .submenu_button {
        padding-right: 40px;
        -webkit-transition: background 0.5s;
        -moz-transition: background 0.5s;
        -ms-transition: background 0.5s;
        -o-transition: background 0.5s;
        transition: background 0.5s;
    }
    .submenu_button:after {
        right: 14px;
        margin-top: -8px;
        background: url('/img/svg/down_black.svg') no-repeat;
        background-size: 19px auto;
        background-position: center;
    }
    .menu_item.opened .submenu_button,
    .has_sublevel.opened .sublevel_button{
        background: #e1e1e1;
    }
    .menu_list {
        width: 100%;
        margin: 0;
    }
    .menu_list a {
        white-space: normal !important;
    }
    .submenu_list,
    .sublevel_menu{
        position: relative;
        top: auto;
        left: auto !important;
        right: auto !important;
        padding: 0 !important;
        background: #f4f4f4;
    }
    .sublevel_menu ul {
        padding: 0;
        background: transparent;
    }
    .submenu_list li {
        margin-top: 1px;
    }
    .submenu_list a {
        font-size: 15px;
        line-height: 20px;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        color: #303030 !important;
        background: #ebebeb;
    }
    .submenu_list a:hover {
        background: #ebebeb;
    }
    .submenu_list li:first-child a {
        text-align: left;
        background: rgba(235,235,235,0.7);
    }
    .submenu_list > li > a {
        padding-left: 30px !important;
        padding-right: 14px;
    }
    .submenu_list > li > a.sublevel_button {
        padding-right: 35px;
    }
    .sublevel_button:after {
        display: block;
        position: absolute;
        right: 14px !important;
        left: auto !important;
        top: 50%;
        margin-top: -7px;
        width: 11px;
        height: 14px;
        content: "";
        background: url(/img/svg/down_black.svg) no-repeat;
        background-size: 15px;
        background-position: center;
    }
    .sublevel_menu a {
        padding-left: 42px !important;
        padding-right: 14px !important;
    }
    .menu_item.opened .submenu_button:after,
    .has_sublevel.opened .sublevel_button:after {
        -webkit-transform: scaleY(-1);
        -moz-transform: scaleY(-1);
        -ms-transform: scaleY(-1);
        -o-transform: scaleY(-1);
        transform: scaleY(-1);
    }
    .phone_block {
        padding: 15px 14px;
        width: 100%;
        font-size: 14px;
        line-height: 28px;
    }
    .phone_number {
        background: url('/img/svg/phone_black.svg') no-repeat;
        background-size: 28px;
        background-position: left center;
        color: #303030 !important;
        opacity: 1 !important;
        padding-left: 35px;
    }
    .header_socials {
        padding: 0 14px;
        width: 100%;
    }
    .header_socials li {
        padding: 0 15px 0 0;
    }
    .header_socials .facebook {
        background: url('/img/svg/facebook_black.svg') no-repeat;
    }
    .header_socials .vkontakte {
        background: url('/img/svg/vk_black.svg') no-repeat;
    }
    .header_socials .googleplus {
        background: url('/img/svg/google_black.svg') no-repeat;
    }
    .header_socials .youtube {
        background: url('/img/svg/youtube_black.svg') no-repeat;
    }
    .header_socials .e-mail {
        background: url('/img/svg/mail_black.svg') no-repeat;
    }
    .currency_block {
        width: 100%;
        margin-top: 15px;
        padding: 0 14px 15px;
    }
    .currency_button {
        display: block;
        text-align: right;
        font-size: 14px;
        padding: 15px 20px 15px 0;
        border-top: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
        background: url(/img/svg/down_black.svg) no-repeat;
        background-size: 13px;
        background-position: right center;
        line-height: 20px;
    }
    .currency_hint {
        display: block;
        float: left;
        color: #303030;
    }
    .currency_list {
        display: block !important;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        overflow: hidden;
        opacity: 0;
        background: rgba(70,70,70,0.4);
        -webkit-transition: opacity 0.3s;
        -moz-transition: opacity 0.3s;
        -ms-transition: opacity 0.3s;
        -o-transition: opacity 0.3s;
        transition: opacity 0.3s;
        z-index: 9999;
    }

    .header_socials a {
        width: 28px;
        height: 28px;
        background-size: 28px !important;
    }
    .header_menu_inner.currency_opened {
        padding-left: 120px;
        margin-left: 0;
        background:transparent;
        overflow: hidden;
    }
    .header_menu_inner.currency_opened .currency_list {
        height: 100%;
        opacity: 1;
        overflow-y: auto;
    }
    .currency_list .list_inner {
        display: table;
        width: 100%;
        margin: 0 auto;
        padding: 20px 0;
        height: 100%;
        max-width: 460px;
        padding: 0 30px;
    }
    .currency_list .list_container {
        display: table-cell;
        vertical-align: middle;
    }
    .currency_close {
        display: block;
        float: right;
        width: 50px;
        height: 50px;
        background: url('/img/svg/close.svg') no-repeat;
        background-size: 14px;
        background-position: center;
        position: relative;
        z-index: 5;

    }
    .currency_block ul:before,
    .currency_block ul:after {
        display: none;
    }
    .currency_block ul {
        background: #ffffff;
        border: none;
        box-shadow: none;
        margin-top: 0;
        padding: 50px 14px;
    }
    .currency_list li {
        border-bottom: 1px solid #e3e4e4;
    }
    .currency_list li:first-child {
        border-top: 1px solid #e3e4e4;
    }
    .currency_list li a {
        font-size: 14px;
        line-height: 30px;
        padding: 10px 0;
        position: relative;
        color: #303030 !important;
        opacity: 1 !important;
    }
    .currency_list li.active a {
        color: #4b80b0 !important;
        background: transparent;
    }
    .currency_list li a:before {
        display: block;
        content: "";
        float: right;
        margin-right: 4px;
        width: 18px;
        height: 18px;
        border: 2px solid #303030;
        border-radius: 50%;
        margin-top: 6px;
    }
    .currency_list li.active a:after {
        display: block;
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #4a81b1;
        top: 20px;
        right: 8px;
    }
    #itinerary .custom_accordion_content .right {
        max-width: 405px;
        margin-bottom: 10px;
        float: left;
        position: relative;
        z-index: 3;
        padding-right: 20px;
        padding-left: 15px;

    }
    #itinerary .accardion_item.last .custom_accordion_content .right {
        margin: 0;
        min-height: 0;
    }

    #itinerary .custom_accordion_content .left.col-md-6 {
        width: 100%;
        float: none;
        margin-top: -7px;
        text-align: justify
    }
    .travelers_items .rating_block {
        height: 15px;
        width: 75px;
        margin-top: 2px;
    }
    .travelers_items .rating_block span {
        background-size: 15px !Important;
    }
    #essential_information li:before {
        background-position: -1px 1px;
    }
    .single_tour_details,
    .packing_checklist_item {
        text-align: justify
    }
    .banner_review .reviews a {
        line-height: 18px;
        margin-top: 5px;
        display: inline-block;
        vertical-align: top;
    }
    .custom_pagination a i {
        height: 24px;
    }
}
@media screen and (max-width: 991px) {
    .guide_image_block {
        padding: 0;
        width: auto;
        max-width: 100%;
        float: none;
    }
    .black_subtitle {
        margin-top: 25px;
    }
    .guide_info_block {
        padding-left: 0;
    }
    div#design-your-tour-success {
        margin-top: 80px;
        font-size: 20px;
    }
    .adv_slider .travelers_items h4 {
        height: 60px;
    }
    .tours_first_block .col-xs-12 {
        max-width: 680px;
        float: none;
        margin-left: auto;
        margin-right: auto;
    }
    .tours_first_block .right {
        padding-top: 20px;
    }
    .tours_first_block .single_tour_details span {
        padding-right: 0;
    }
    .current_day_text,
    .current_day_description{
        padding-right: 0;
    }
    #itinerary .current_day_text > div {
        padding-right: 20px;
    }
    .clients_slider .slider_line .slide_block {
        width: 25%;
    }
    .clients_slider .slick-prev {
        left: 0;
    }
    .clients_slider .slick-next {
        right: 0;
    }
    .bike_characteristics:before,
    .bike_characteristics:after {
        display: none
    }
    .bike_characteristics > div {
        width: 100%;
        margin-top: 25px;
    }

    .bike_characteristics > div:first-child {
        margin: 0;
    }
    .tour_details .small_group:before,
    .tour_details .tour_date:before,
    .tour_details .duration:before,
    .tour_details .BB:before,
    .tour_details .age:before,
    .tour_details .tour_price_block:before {
        top: 16px;
    }
    .contacts_links {
        margin: 0 -15px;
    }
    .contacts_links li {
        padding: 20px 15px;
    }
    .contact_icon {
        margin-right: 10px;
    }
    .contact_name {
        padding-left: 5px;
    }
    .own_tour_head {
        height: 360px;
        font-size: 40px;
        line-height: 50px;
    }
    .own_tour_form {
        margin-top: -50px;
    }
    .own_tour_form form {
        padding: 50px 15px 30px;
    }
    .own_tour_form form:before {
        margin-bottom: 20px;
    }
    .own_tour_form .field_name {
        font-size:18px;
        line-height: 24px;
        margin-bottom: 20px;
    }
    .own_tour_form .checkbox_button {
        font-size: 16px;
        line-height: 24px;
    }
    .own_tour_form .checkbox_button:before {
        width: 24px;
        height: 24px;
    }
    .own_tour_form .checkbox_button.checked:before {
        background-size: 16px auto;
    }
    .own_tour_form .count_field {
        min-width: 185px;
    }
    .own_tour_form .count_block {
        padding: 9px 15px;
        width: 140px;
        border-width: 1px;
    }
    .own_tour_form .count_block a{
        width: 20px;
        height: 20px;
        margin-left: 15px;
    }
    .own_tour_form .count_block input {
        font-size: 16px;
        line-height: 20px;
        width: 30px;
    }
    .own_tour_form .field_hint {
        font-size: 14px;
        line-height: 17px;
    }
    .own_tour_form .count_field .field_hint {
        min-width: 45px;
        padding: 11px 15px 11px 0;
    }
    .own_tour_form .form_field > input,
    .own_tour_form textarea {
        font-size: 14px;
        line-height: 20px;
        border-width: 1px;
        padding: 9px 15px;
    }
    .own_tour_form textarea {
        height: 120px;
    }
    .own_tour_form button {
        font-size: 18px;
        line-height: 23px;
        padding: 11px 15px;
    }
}
@media screen and (max-width: 767px) {
    #arara_team .member_profesion .text {
        height: auto;
        max-height: 160px;
        margin: 20px 0;
    }
    #arara_team .current_member_position {
        min-height: 0;
        padding-bottom: 0;
    }
    #itinerary .custom_accordion_content .right {
        max-width: 100%;
        padding-right: 0;
    }
    .banner_review .reviews a {
        margin-top: 2px;
    }
    .tours_first_block .col-xs-12 {
        max-width: 480px;
    }
    #highlights {
        padding-bottom: 20px;
    }
    .tours_first_block .right {
        padding-bottom: 10px;
    }
    .header_menu_block:before {
        left: 54px;
    }
    #itinerary .custom_accordion_content .right,
    #itinerary .custom_accordion_content .left {
        padding-right: 0;
        padding-left: 0;
    }
    .header_menu_inner {
        margin-left: 54px;
    }
    .header_menu_inner.currency_opened {
        margin-left: 0;
        padding-left: 54px;
    }
    .small_group:before,
    .tour_date:before,
    .duration:before,
    .BB:before,
    .age:before,
    .tour_price_block:before {
        top: 10px;
    }
    .tour_details .small_group:before,
    .tour_details .tour_date:before,
    .tour_details .duration:before,
    .tour_details .BB:before,
    .tour_details .age:before,
    .tour_details .tour_price_block:before {
        top: 0;
        height: 14px;
    }
    .tour_details .small_group:before {
        background-size: auto 14px;
        margin-top: -2px;
    }
    .tour_details .tour_date:before {
        background-size: auto 14px;
        margin-top: -1px;
    }
    .tour_details .duration:before {
        background-size: auto 17px;
        margin-top: -1px;
    }
    .tour_details .BB:before {
        background-size: auto 18px;
        margin-top: -2px;
    }
    .tour_details .age:before {
        background-size: auto 18px;
        margin-top: -1px;
    }
    .tour_details .tour_price_block:before {
        background-size: auto 14px;
        margin-top: -1px;
    }
    #itinerary .custom_accordion_content .left.col-md-6 {
        float: left;
        margin-top: 0;
    }
    .header_buttons {
        margin: 0 -3px;
    }
    .main_logo img {
        width: auto;
        height: 42px;
    }
    .main_logo {
        padding: 13px 0;
    }
    .clients_slider .slider_line {
        margin: 0;
        padding: 0 10px;
    }
    .clients_slider .slider_line:before,
    .clients_slider .slider_line:after {
        display: none;
    }
    .clients_slider .slider_line .slide_block {
        padding: 0 15px;
    }
    .clients_slider .slick-arrow {
        width: 10px;
        height: 18px;
        background-size: 18px;
        margin-top: -9px;
    }
    .page_text_block,
    .contacts_section .field_name,
    .about_who_are_content,
    .about_our_mission,
    .about_our_guides,
    .about_our_team,
    .about_offer_content,
    .hotel_info_text,
    .details_list p{
        font-size: 16px;
        line-height: 27px;
    }
    .standard_info_page .two_cols_list {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }
    .check_type_list li,
    .arrow_type_list li,
    .round_arrow_list li {
        font-size: 16px;
        padding-bottom: 15px;
        padding-left: 20px;
        line-height: 27px;
    }
    .bike_characteristics ul {
        padding: 0;
    }
    .round_arrow_list li:before {
        background-size: 10px auto;
    }
    .check_type_list li:before {
        background-size: 13px auto;
    }
    .arrow_type_list li:before {
        width: 10px;
        background-position: right center;
        background-size: 12px auto;
    }
    .download_link {
        font-size: 12px;
        line-height: 16px;
        padding: 4px 0;
        width: 100%;
        text-align: center;
    }
    .standard_info_page .arrow_type_list + * {
        margin-top: 10px;
    }

    .contacts_section .form_block input,
    .contacts_section .form_block textarea {
        font-size: 16px;
        line-height: 24px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .contacts_section .form_block textarea {
        height: 192px;
    }
    .contacts_section .form_button {
        font-size: 16px;
    }
    .character_name{
        font-size: 16px;
        line-height: 18px;
    }
    .bike_characteristics .character_icon img {
        height: 36px !important;
        margin: 0 !important;
    }
    .member_name {
        padding: 17px 25px 10px;
        height: auto;
    }
    .contacts_links {
        padding: 20px 0 25px;
        margin: 0;
    }
    .contacts_links li {
        padding: 5px 0;
        width: 50%;
    }
    .contacts_links a {
        display: inline-block;
        vertical-align: top;
    }
    .contact_icon {
        width: 44px !important;
        margin-right: 8px;
        text-align: center;
    }

    .facebook_icon img {
        margin-left: 0;
    }
    .contact_name {
        padding: 20px 0 20px 5px;
        font-size: 16px;
    }




    .it_footer_block {
        width: 100%;
        border: none;
        padding-top: 20px;
        padding-bottom: 0;
        font-size: 16px;
    }
    .it_footer_block:first-child {
        padding-top: 0;
    }
    .landing_title .hotel_stars {
        padding-top: 2px;
    }
    .landing_title .star_block {
        width: 25px;
        height: 25px;
    }
    .own_itinerary.epilogue_decor {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 95px;
    }
    .own_itinerary .table_block {
        height: 35px;
        position: relative;
    }
    own_itinerary_info {
       width: 100%;
    }
    .own_itinerary_button {
        padding-left: 0;
        position: absolute;
        top: 65px;
        left: 0;
        width: 100%;
        text-align: center;
    }
    .own_itinerary_button a {
        color: #2f7dc9 !important;
        font-size: 17px;
        line-height: 23px;
        padding: 5px 15px;
    }
    .own_itinerary_button a:hover {
        opacity: 1;
    }

    .messenger_open {
        font-size: 0;
        padding: 0;
        min-width: 0;
        background: transparent;
        height: 24px;
        margin-bottom: 10px;
        opacity: 1 !important;
    }
    .messenger_open:before {
        position: relative;
        left: auto;
        top: auto;
    }
    .messeneger_open:after {
        display: none;
    }
    .messenger_inner {
        position: fixed;
        right: 0;
        width: 100%;
        background: #ffffff;
    }
    .messenger_opened {
        overflow: hidden;
    }
    .messenger_opened .messenger_inner {
        height: 100% !important;
    }
    .messenger_head {
        background: #2f7dc9;
        font-size: 16px;
        padding: 5px 45px;
        text-align: center;
    }
    .messenger_close {
        width: 22px;
        right: 13px;
        height: 22px;
        top: 4px;
        background-size: 12px;
    }
    .messenger_form .form_inner {
        max-width: 390px;
        margin: 0 auto;
        padding: 15px;
    }
    .about_messenger {
        padding-bottom: 15px;
    }
    .messenger_form textarea {
        height: 78px;
    }
    .own_tour_head {
        height: 300px;
        font-size: 30px;
        line-height: 36px;
    }
    .adv_slider .travelers_items {
        width: 50%;
    }
    .adv_slider .travelers_items h4 {
        height: 40px;
    }
    .dayly_slider {
        margin-bottom: 0 !important;
    }
    .tours_first_block .right {
        padding-bottom: 0 !important;
    }
}
@media screen and (max-width: 599px) {
    .adv_slider .travelers_items {
        width: 100%;
    }
    .travelers_items h4 {
        height: auto;
    }
    .travelers_content .col-xs-6,
    .book_with_Arara .col-xs-6,
    .all_tours_row .col-xs-6,
    .hotels_listing .col-xs-6{
        width: 100%;
    }
    .pos1 .about_book_text:before,
    .pos0 .about_book_text:before,
    .pos1 .about_book_text:after,
    .pos0 .about_book_text:after{
        left: 50%;
    }
    .own_tour_form .field_name {
        font-size:16px;
        line-height: 20px;
        margin-bottom: 15px;
    }
    .own_tour_form .checkbox_button {
        font-size: 14px;
    }
    .own_tour_form .count_field {
        min-width: 185px;
    }
    .own_tour_form .count_block input {
        font-size: 14px;
        line-height: 20px;
    }
    .own_tour_form .field_hint {
        font-size: 14px;
        line-height: 17px;
    }
    .own_tour_form .form_field.fl,
    .own_tour_form .form_field.fr {
        width: 100%;
        max-width: 100%;
        padding-bottom: 20px;
        padding-right: 0;
        padding-left: 0;
    }
    .own_tour_form button {
        width: 100%;
    }
    .own_tour_form button {
        font-size: 14px;
        line-height: 20px;
        padding: 10px 15px;
    }
    .own_tour_form .form_field {
        padding-bottom: 20px;
    }
}
@media screen and (max-width: 480px) {
    #accommadation .row {
        font-size: 0;
    }
    #accommadation .row .col-xs-6 {
        float: none;
        display: inline-block;
        vertical-align: top;
    }
    .tour_hottels .hotel_stars {
        right: 10px;
        bottom: 10px;

    }
    .tour_hottels .star_block{
        width: 20px;
        height: 20px;
    }
    .custom_pagination a i {
        height: 19px;
        background-size: 11px !important;
    }
    .clients_slider .slider_line .slide_block {
        padding: 0 5px;
        width: 33.33%;
    }
    .clients_slider .slick-prev {
        left: -5px;
    }
    .clients_slider .slick-next {
        right: -5px;
    }
    .tour_second_accordions .first li:before {
        top: 8px;
    }
    .own_tour_form .checkbox_label {
        width: 50%;
    }
    .own_tour_form .count_field {
        margin-top: 10px;
        min-width: 200px;
    }
    .own_tour_form .field_name + .count_field {
        margin-top: 0;
    }
}
@media screen and (max-width: 479px) {
    .contacts_links li {
        width: 100%;
    }
}
@media screen and (max-width: 399px) {
    .viber_whatsapp {
        font-size: 12px;
    }
    .phone_block {
        padding-right: 5px;
    }
}
@media screen and (max-width: 374px) {
    .supplement_price {
        margin-left: 7px;
        padding-left: 7px;
    }
}
@media screen and (max-width: 359px) {
    .viber_whatsapp {
        display: block;
        margin-left: 35px;
        margin-top: -5px;
        position: relative;
        z-index: 5;
    }
    .tour_second_accordions .first li {
        font-size: 14px;
    }
    .supplement_type {
        width: 165px;
    }
}