.item-title {
    margin: 0px 0px 10px 0;
    font-weight: 700;
}
.item-description {
    margin: 5px 0;
    font-size: 14px;
}
#paging>ul {
    list-style: none;
}
#paging>ul>li {
    display: inline-block;
    padding: 0 10px;
}
#paging>ul>li.active a {
    font-weight: 700;
    color: #387ab9;
}

span.current_curency {
    padding-left: 5px;
}
.dn{
    display: none;
}
@media (max-width: 1024px) {
    .hide-mobile{
        display: none;
    }
    .show-mobile{
        display: block;
    }
}
@media (min-width: 1024px) {
    .hide-mobile{
        display: block;
    }
    .show-mobile{
        display: none;
    }
}

.br-theme-fontawesome-stars .br-widget a.br-selected:after {
    color: #397fba !important;
    font-size: 25px !important;
}
.br-theme-fontawesome-stars .br-widget a, .br-theme-fontawesome-stars .br-widget a:hover {
    font-size: 25px !important;
}
.br-theme-fontawesome-stars .br-widget a.br-active:after{
    color: #397fba !important;
}

@media(min-width: 992px) {
    .tour_details_mobile{
        display: none;
    }
}
@media(max-width: 991px) {

    .tour_price_block:after {
        content: '';
        display: block;
        clear: both;
    }
}



.tour_banner.mobile .tour_img_web, .tour_banner.tablet .tour_img_web{
    display: none;
}

.tour_banner.tablet .tour_img_tablet, .tour_banner.mobile .tour_img_mobile{
    display: block;
}

@media (min-width: 1023px) {
    .tour_img_tablet{
        display: none !important;
    }
    .tablet .tour_img_ipad_land{
        display: block !important;
    }
}


.tour_img_mobile, .tour_img_tablet, .tour_img_web,  .tour_img_ipad_land{
    width: 100%;
}

@media (max-width: 320px) {
    .tour_menu_book_now {
        margin-left: 0 !important;
    }
}
.header_bottom_inner:after {
    content: '';
    clear: both;
    display: block;
}
div#similar_tours .row {
    margin-bottom: 40px;
}
div#similar_tours .row:last-child{
    margin-bottom: 0px;
}



@media screen and (max-width: 1024px) and (min-width: 768px) {
    .slide_info_block {
        top: 68px;
        bottom: 98px;
        padding: 0 15px;
    }
    /*#custom_carousel .slide_title {
        font-size: 72px;
        line-height: 72px;
    }*/
    .slide_info_inner {
        max-width: 100%;
    }
    #custom_carousel .slide_title:after {
        display: block;
        content: "";
        max-width: 472px;
        height: 1px;
        background-color: #fff;
        opacity: 0.4;
        margin-top: 30px;
    }
  /*  #custom_carousel a.slider_button {
        font-size: 42px;
        line-height: 68px;
        padding-right: 76px;
        margin-top: 10px;
    }
    #custom_carousel .slide_text {
        font-size: 42px;
        line-height: 68px;
        margin-top: 10px;
    }*/
}
#events_form .form-group, #motorbike-rental .form-group{
    margin-bottom: 0;
}
.web .slider_img_ {
    height: 762px;
    max-height: 100vh;
}
.mobile .slider_img_{
    height: 667px;
}
.tablet .slider_img_{
    height: 1024px;
    max-height: 100vh;
}
.mobile .large,.tablet .large{
    display: none;
}
.mobile .small{
    display: block;
}
@media screen and (orientation:portrait) {
    .middle{
        display: block;
    }
    .ipad_land {
        display: none;
    }
    @media screen and (min-width: 1024px) {
        .tablet .slider_img_{
            height: 1366px;
        }
    }
}
@media screen and (orientation:landscape) {
    .middle {
        display: none;
    }
    .ipad_land {
        display: block;
    }
    .mobile .slider_img_ {
        max-height: none !important;
    }
}

.footer_top_inner.bottom img {
    width: 100%;
}
.two_columns {
    margin:0;
    padding:0;
    list-style-type:none;
    -webkit-column-count:2;
    column-count:2;
    -webkit-column-gap:50px;
    column-gap:50px
}
.two_columns > li,
.two_columns > div {
    font-size: 18px;
    color: #858585;
    line-height: 30px;
    position: relative;
    padding-left: 50px;
    padding-bottom: 22px;
}
@media screen and (max-width: 767px) {
    .two_columns {
        -webkit-column-count: 1;
        column-count: 1;
    }
}

.two_columns > li:before,
.two_columns > div:before {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    top: 5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #7d7d7d;
}