
@font-face {
    font-family: 'BentonSans-Bold';
    src: url('/fonts/BentonSans-Bold/BentonSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/BentonSans-Bold/BentonSans-Bold.otf')  format('opentype'),
    url('/fonts/BentonSans-Bold/BentonSans-Bold.woff') format('woff'),
    url('/fonts/BentonSans-Bold/BentonSans-Bold.ttf')  format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSans-Regular';
    src: url('/fonts/BentonSans-Regular/BentonSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/BentonSans-Regular/BentonSans-Regular.otf')  format('opentype'),
    url('/fonts/BentonSans-Regular/BentonSans-Regular.woff') format('woff'),
    url('/fonts/BentonSans-Regular/BentonSans-Regular.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'BentonSans-Book';
    src: url('/fonts/BentonSans-Book/BentonSans-Book.eot?#iefix') format('embedded-opentype'),
    url('/fonts/BentonSans-Book/BentonSans-Book.otf')  format('opentype'),
    url('/fonts/BentonSans-Book/BentonSans-Book.woff') format('woff'),
    url('/fonts/BentonSans-Book/BentonSans-Book.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: BentonSans_black;
    src: url(/fonts/BentonSans_black.otf);
}

@font-face {
    font-family: BentonSans_medium;
    src: url(/fonts/BentonSans_medium.otf);
}



@font-face {
    font-family: BentonSans_thin;
    src: url(/fonts/BentonSans_thin.otf);
}



@font-face {
    font-family: BentonSans_book_regular;
    src: url(/fonts/BentonSans_Book_Regular.otf);
}
.custom_container {
    max-width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
}
a:hover {
    text-decoration: none;
}
.hidden_div {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 2;
    display: none;
}
.about_tour_book {
    display:none;
    position: absolute;
    top: -3px;
    left: 1px;
    right: 15px;
    max-width: 230px;
    border: 1px solid #d2d2d2;
    border-radius: 7px;
    background: rgba(255,255,255,0.75);
    z-index: 2;
    font-size: 11px;
    color: #4c4c4c;
    line-height: 11px;
    padding: 3px 25px;
}
/*

!* For 1x devices *!
.sprite, .tour_sprite:before, .acc_sprite:after {
    background-image: url('/img/sprite.png?v=1.2');
    background-repeat: no-repeat;
}

!* For 2x devices *!

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 2) {
    .sprite, .tour_sprite:before, .acc_sprite:after,#events .events_top_content li:before {
        background-image: url('/img/sprite2x.png?v=1.2');
        background-size: 296px auto;
    }

}

!* For 3x devices *!

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 480dpi) {
    .sprite, .tour_sprite:before, .acc_sprite:after, #events .events_top_content li:before {
        background-image: url('/img/sprite3x.png?v=1.2');
        background-size: 296px auto;
    }

}

!* For 4x devices *!

@media (-webkit-min-device-pixel-ratio: 4), (min-resolution: 640dpi) {
    .sprite, .tour_sprite:before, .acc_sprite:after,#events .events_top_content li:before {
        background-image: url('/img/sprite4x.png?v=1.2');
        background-size: 296px auto;
    }

}
*/

/*header styles*/
body {
    font-family: 'BentonSans-Book', Arial;
    color: #858585;
    touch-action: manipulation;
}

/*header new styles*/
.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 0 15px;
}
.main_logo {
    position: absolute;
    z-index: 10;
    top: 47px;
    left: 0;
}
.main_logo a {
    display: inline-block;
    vertical-align: top;
}
.main_logo img {
    width: 140px;
}
.header:before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 35px;
    width: 100%;
    background: #1c1e21;
}
.header_inner {
    position: relative;
    z-index: 10;
    max-width: 1140px;
    margin: 0 auto;
}
.languages {
    font-family: Verdana;
}

.header_top_left {
    font-family: Verdana;
    position: absolute;
    top: 0;
    left: 0;
    color: #ffffff;
}
.header_socials ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.header_socials li {
    float: left;
    padding: 6px 10px 7px 0;
}
.header_socials a {
    display: block;
    width: 22px;
    height: 22px;
    background-size: 22px !important;
}
.header_socials .facebook {
    background: url('/img/svg/facebook_white.svg') no-repeat;
}
.header_socials .vkontakte {
    background: url('/img/svg/vk_white.svg') no-repeat;
}
.header_socials .googleplus {
    background: url('/img/svg/google_white.svg') no-repeat;
}
.header_socials .youtube {
    background: url('/img/svg/youtube_white.svg') no-repeat;
}
.header_socials .e-mail {
    background: url('/img/svg/mail_white.svg') no-repeat;
}
.phone_block {
    font-size: 13px;
    padding-left: 21px;
    padding-top: 6px;
    line-height: 22px;
}
.phone_number {
    display: inline-block;
    vertical-align: top;
    color: #ffffff !important;
    padding-left: 27px;
    background: url('/img/svg/phone_white.svg') no-repeat;
    background-size: 22px;
    background-position: left center;
}
.viber_whatsapp {
    display: inline-block;
    vertical-align: top;
}
.viber_whatsapp a {
    display: inline-block;
    vertical-align: top;
}
.viber_whatsapp .whatsapp {
    color: #55bf5f
}
.viber_whatsapp .viber {
    color: #79519a
}
.languages {
    text-align: right;
    position: relative;
    overflow: hidden;
    padding: 9px 0;
    height: 35px;
}
.languages ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 0;
}
.languages ul li {
    display: inline-block;
    vertical-align: top;
    padding-left: 22px;
    margin-left: 22px;
    border-left: 1px solid #ffffff;
}
.languages ul li:first-child {
    border:none;
}
.languages a {
    display: block;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
}

.currency_block {
    position: relative;
    margin-left: 55px;
    padding: 9px 0 10px;
    line-height: 16px;
}
.currency_hint,
.currency_close {
    display: none;
}
.currency_button {
    display: block;
    color:#3671a7 !important;
    padding-right: 17px;
    background: url('/img/svg/down_white.svg') no-repeat;
    background-size: 11px auto;
    background-position: right center;
}
.currency_list {
    position: absolute;
    top: 29px;
    left: -15px;
    width: 88px;
    padding-top: 2px;
    display: none;
    z-index: 10;
}
.currency_list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    background: #121212eb;
    border: 1px solid #3a3c3f;
    box-shadow: 0 3px 3px rgba(0,0,0,.2);
    position: relative;
}
.currency_list ul:before {
    content: "";
    position: absolute;
    right: 27px;
    top: -6px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px;
    border-color: transparent transparent rgba(24,24,23,.94);
    z-index: 2;
}
.currency_list ul:after {
    content: "";
    position: absolute;
    right: 26px;
    top: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 7px;
    border-color: transparent transparent #3a3c3f;
    z-index: 1;
}
.currency_list a {
    display: block;
    color: #ffffff !important;
    line-height: 18px;
    padding: 3px 15px;
}
.main_menu {
    padding-top: 22px;
}
.menu_list {
    margin: 0 -12px 0 0;
    padding: 0;
    list-style-type: none;
}
.menu_item {
    float: left;
    position: relative;
    padding: 0 12px 20px;
}
.menu_item > a {
    display: block;
    color: #ffffff !important;
    line-height: 36px;
    font-size: 16px;
}
.submenu_button {
    position: relative;
    padding-right: 23px;
}
.submenu_button:after {
    display: block;
    content: "";
    position: absolute;
    width: 13px;
    height: 16px;
    right: 0;
    top: 50%;
    margin-top: -10px;
    background: url('/img/svg/down_white.svg') no-repeat;
    background-size: 25px auto;
    background-position: center;
}
.submenu_list {
    position: absolute;
    top: 100%;
    left: 12px;
    background: #ffffff;
    white-space: nowrap;
    margin: 0;
    padding: 30px 0;
    list-style-type: none;
    display: none;
    z-index: 10;
}
.menu_item:nth-child(n+4) .submenu_list {
    left: auto;
    right: 0;
}
.header_inner:after {
    position: absolute;
    display: block;
    content: "";
    height: 1px;
    bottom: -1px;
    background: #fff;
    opacity: .4;
    left: -35px;
    right: -35px;
}
.submenu_list > li {
    position: relative;
}
.submenu_list li:after,
.submenu_list li:first-child:before {
    display: block;
    content: "";
    margin: 0 30px;
    height: 1px;
    background: #e3e4e4;
}
.submenu_list a {
    display: block;
    font-size: 18px;
    line-height: 24px;
    padding: 7px 30px 5px;
    color: #424141 !important;
}
.submenu_list a.sublevel_button {
    position: relative;
    padding-right: 60px;
}
.menu_item:nth-child(n+3) .submenu_list a.sublevel_button {
    padding-right: 30px;
}
.sublevel_button:after {
    display: block;
    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -7px;
    width: 6px;
    height: 14px;
    content: "";
    background: url('/img/svg/right_black.svg') no-repeat;
    background-size: 14px;
    background-position: -5px center;
}
.menu_item:nth-child(n+3) .sublevel_button:after {
    right: auto;
    left: 12px;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.sublevel_menu {
    position: absolute;
    top: 0;
    display: none;
    left: 100%;
    padding-left: 1px;
}
.menu_item:nth-child(n+3) .sublevel_menu{
    left: auto;
    padding-left: 0;
    right: 100%;
    padding-right: 1px;
}
.sublevel_menu ul {
    margin: 0;
    padding: 30px 0;
    list-style-type: none;
    background: #ffffff;
}





.header a {
    text-decoration: none !important;
}
.header_top_left a,
.main_logo a,
.currency_block a,
.menu_item > a{
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}

.languages a {
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}
.submenu_list a {
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    -ms-transition: background 0.3s;
    -o-transition: background 0.3s;
    transition: background 0.3s;
}
.submenu_list a:hover,
.has_sublevel.opened .sublevel_button {
    background: #f2f2f2;
}
.languages .active a,
.languages a:hover {
    color: #3671a7;
}
.header_top_left a:hover,
.main_logo a:hover,
.currency_block a:hover,
.menu_item > a:hover {
    opacity: 0.7;
}
.currency_list .active a {
    background: #337ab7;
    opacity: 1 !important;
}
.languages .active a {
    cursor: default;
    pointer-events: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

/*header new styles end*/



.new_carrency {
    float: left;
}

.new_carrency .nav.navbar-nav li:last-child a {
    padding-right: 15px;
}

.new_carrency .dropdown-toggle {
    color: #3671a7;
}

.currency {
    display: inline-block;
    float: left;
    line-height: 47px;
    height: 47px;
    padding: 0px 35px;
    position: relative;
}

.currency .active:after {
    content: "";
    width: 30px;
    height: 8px;
    background-position: -214px -20px;
    position: absolute;
    top: 40%;
    right: 0px;
}

.currency.curr_open {
    height: 70px;
    z-index: 99;
    background: #121212bd;
    border-radius: 3px;
    top: 5px;
    line-height: 22px;
    padding: 0px 30px 0 5px;
}

.currency.curr_open li {
    display: block;
}

.currency.curr_open .active:after {
    transform: rotate(180deg);
    top: 12px;
}

.mobile_lang {
    margin-left: 65px;
}

.currency li {
    color: #3671a7;
    font-size: 14px;
    cursor: pointer
}

.currency li {
    display: none;
}

.currency .active {
    display: block
}

.header_language {
    display: inline-block;
    float: right;
    line-height: 35px;
    font-size: 13px;
}

.header_language li {
    float: left;
}

.header_language li a:hover {
    color: #3671a7
}

.header_language li:last-child a {
    padding-right: 0;
    border: none;
}

.header_language li a {
    padding: 0px 22px;
    color: #fff;
    border-right: 1px solid #fff;
}

.header_language .active a {
    color: #3671a7
}

.header_bottom {
    width: 100%;
    position: absolute;
    top: 40px;
    z-index: 10;
}
.header_bottom_inner {
    position: relative;
}
.header_bottom_inner:before {
    position:absolute;
    display:block;
    content:"";
    height: 1px;
    bottom: -6px;
    background: #ffffff;
    opacity: 0.4;
    left: -35px;
    right: -35px;
}
.logo {
    width: 25%;
    float: left;
    text-align: left;
    padding: 7px 0;
}

.logo img {
    width: 140px;
    height: 54px;
}

.menu {
    display: block;
    float: right;
    margin-top: 10px;
}

#navbar {
    padding-right: 0px;
}

.navbar-toggle .icon-bar {
    background: #fff;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
    background: transparent;
}

.nav > li > a:hover {
    color: #ffffff !important;
    text-decoration: initial;
    background: transparent
}

.nav > li > a:focus {
    color: #fff !important;
    text-decoration: initial;
    background: transparent
}

.dropdown .dropdown_arrow:after {
    content: "\f107";
    position: absolute;
    right: 8px;
    top: 20px;
    width: 16px;
    height: 16px;
    background: url('/img/svg/down_white.svg') no-repeat;
    background-size: 16px auto;
    background-position: center;
}

.dropdown .currency_button .dropdown_arrow:after {
    font-size: 18px;
}
.dropdown.last .dropdown_arrow:after {
    right: -1px;
}



.navbar-inverse.side-collapse .header_top_inner_right {
    width: 100%;
    display: inline-block;
    padding: 0px 15px;
}

.navbar-inverse.side-collapse .header_top_inner_right a, .navbar-inverse.side-collapse .header_top_inner_right li {
    color: #303030
}

.navbar-inverse.side-collapse .header_top_inner_right .active, .navbar-inverse.side-collapse .header_top_inner_right .active a {
    color: #3671a7
}


.navbar-inverse.side-collapse.in #navbar .dropdown:nth-last-child(-n+3) .sublevel_button:before {
    left: 0;
    right: 25px;
}
.sublevel_opened .sublevel_button:before {
    opacity: 0.11;
}

.navbar-inverse.side-collapse.in #navbar .dropdown:nth-last-child(-n+3) .sublevel_button:after {
    position: absolute;
    left: 20px;
    margin-left: 0;
    top: 4px;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.navbar-inverse.side-collapse.in #navbar .dropdown:nth-last-child(-n+3) .sublevel_menu {
    left: auto;
    padding-left: 0;
    right: 100%;
    padding-right: 10px;
}


    /*
    {
        color: #303030
    }*/
.navbar-inverse a.tel{
    float: left;
}
.navbar-inverse a.tel+span{
    float: left;
    padding-left: 16px;
}
.navbar-inverse .social_content ul {
    margin-left: 10px;
}
.navbar-inverse .social_content ul:after {
    content: '';
    display: block;
    clear: both;
}

#navbar li a {
    color: #fff;
    font-size: 16px;
}

#navbar li a.dropdown-toggle {
    padding-right: 32px
}

#navbar li.last a.dropdown-toggle {
    padding-right: 24px
}

#navbar .dropdown-menu a {
    padding: 2px 32px;
    font-size: 18px;
    line-height: 24px;
    color: #424141;
}

#navbar .dropdown-menu > li > a:focus, #navbar .dropdown-menu > li > a:hover {
    text-decoration: none;
    background-color: transparent;
}

.navbar-inverse.side-collapse.in #navbar .dropdown-menu:before {
    content: "";
    position: absolute;
    left: 25px;
    top: -14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 14px 14px 14px;
    border-color: transparent transparent #ffffff transparent;
    z-index: 9999;
}

/*.navbar-inverse.side-collapse.in #navbar .dropdown-menu:after {
    content: "";
    position: absolute;
    left: 13px;
    top: -20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent rgba(255, 255, 255, 0.7411764705882353) transparent;
    z-index: 9998;
}*/

.navbar-inverse.side-collapse.in #navbar .dropdown-menu {
    background: #ffffff;
    border: none;
    box-shadow: none;
    float: left;
    position: absolute;
    margin: 0;
    z-index: 999999999;
    top: 50px;
    width: auto;
    padding-top: 25px;
    padding-bottom: 20px;
    left: 100%;
    margin-left: -56px;
}





.navbar-inverse.side-collapse.in #navbar .last .dropdown-menu:before {
    right: 25px;
    left: auto
}



.navbar-inverse.side-collapse #navbar .dropdown-menu {
    position: relative;
    width: 100%;
    padding: 0;
    background: #f3f3f3;
    border: none;
    box-shadow: none;
}

.navbar-toggle span {
    margin-top: 0px !important;
    position: absolute;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.navbar-toggle span:nth-child(1) {
    top: 0px;
    left: 7px;
}

.navbar-toggle span:nth-child(2) {
    top: 7px;
    left: 7px;
}

.navbar-toggle span:nth-child(3) {
    top: 15px;
    left: 7px;
}

.navbar-toggle.menu_open span:nth-child(1) {
    top: 13px;
    left:5px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.navbar-toggle.menu_open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.navbar-toggle.menu_open span:nth-child(3) {
    top: 13px;
    left:5px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

#new_navbar li a {
    color: #fff;
    font-size: 13px;
}

#new_navbar li a.dropdown-toggle {
    color: #3671a7 !important;
    padding-bottom: 0px;
    padding-top: 0px;
    line-height: 35px
}

#new_navbar li a.dropdown-toggle .dropdown_arrow:after {
    color: #fff;
    top: 7px;
    right: -3px;
}

#new_navbar .dropdown-menu {
    min-width: 100%
}

#new_navbar .dropdown-menu a {
    padding: 3px 15px;
}

#new_navbar .dropdown-menu > li > a:focus, #new_navbar .dropdown-menu > li > a:hover {
    text-decoration: none;
    background-color: transparent;
}

.navbar-inverse.side-collapse.in #new_navbar .dropdown-menu:before {
    content: "";
    position: absolute;
    right: 27px;
    top: -6px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 6px 6px 6px;
    border-color: transparent transparent rgba(24, 24, 23, 0.9411764705882353) transparent;
    z-index: 9999
}

.navbar-inverse.side-collapse.in #new_navbar .dropdown-menu:after {
    content: "";
    position: absolute;
    right: 26px;
    top: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 7px 7px;
    border-color: transparent transparent #3a3c3f transparent;
    z-index: 9998;
}

.navbar-inverse.side-collapse.in #new_navbar .dropdown-menu {
    background: #121212eb;
    border: 1px solid #3a3c3f;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 99999;
    top: 37px;
    width: 88px;
    border-radius: 0;
}

.navbar-inverse.side-collapse.in #new_navbar .dropdown-menu a {
    font-size: 13px;
}

.navbar-inverse.side-collapse #new_navbar .dropdown-menu {
    position: relative;
    width: 100%;
    padding: 0;
}

.navbar-inverse.side-collapse #new_navbar .dropdown-menu.currency_dropdown_menu {
    position: absolute;
    margin-left: 5px;
    background: #f3f3f3;
    border-radius: 3px;
    border: 2px solid #979797;
}

/*home slider styles*/
body {
    background: #f3f3f3
}

#custom_carousel {
    clear: both;
    width: 100%
}

#custom_carousel .slider_img_ {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;

}
#custom_carousel .slide_bg {
    background-position: top center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

#custom_carousel .carousel-inner .item a {
    display: inline-block;
    max-width: 650px;
}

#custom_carousel .carousel-control {
    display: none
}
.slide_info_block {
    position: absolute;
    left: 0;
    top: 113px;
    width: 100%;
    bottom: 88px;
    z-index: 3;
    white-space: normal;
}
.table_block {
    display: table;
    width: 100%;
    height: 100%;
}
.cell_middle {
    display: table-cell;
    vertical-align: middle;
}


#custom_carousel img {
    width: 100%
}
.slide_info_inner {
    max-width: 650px;
    position: relative;
}
#custom_carousel .slide_title {
    font-family: 'BentonSans-Bold', Arial;
    font-size: 50px;
    letter-spacing: 1px;
    max-width: 700px;
    color: #ffffff;
    line-height: 1.1em;
    font-weight: 900;
}
#custom_carousel .slide_title:after {
    display: block;
    content: "";
    max-width: 472px;
    height: 1px;
    background-color: #fff;
    opacity: 0.4;
    margin-top: 40px;
}
#custom_carousel .slide_text {
    font-family: 'BentonSans-Book', Arial;
    font-size: 14px;
    color: #fff;
    line-height: 48px;
    position: absolute;
    top: 100%;
    margin-top: 0;
    left: 0;
    max-width: 100%;
}

#custom_carousel .carousel-caption .btn {
    font-family: 'BentonSans-Book', Arial;
    font-size: 25px;
    color: #3876ae;
    margin-top: 25px;
    margin-left: 7px;
    padding: 15px;
    border-radius: 0;
}

.glyphicon.glyphicon-chevron-left:before {
    content: "";
    background: url(/img/left_arrow.png) no-repeat;
    position: absolute;
    background-size: cover;
    height: 60px;
    width: 30px;
    top: -20px;
    left: 0
}

.glyphicon.glyphicon-chevron-right:before {
    content: "";
    background: url(/img/right_arrow.png) no-repeat;
    position: absolute;
    background-size: cover;
    height: 60px;
    width: 30px;
    top: -20px;
    right: 0;
}

.navbar-inverse.side-collapse {
    background-color: transparent;
}
.slick-slide {
    position: relative;
    overflow: hidden;
    outline: none !important;
}
.slider_button {
    color: rgba(255, 255, 255, 0.87);
    display: block;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.06px;
    transition: color 200ms ease-out;
    padding-top: 30px;
}
#custom_carousel a.slider_button {
    display: inline-block;
    color: #ffffff;
    padding: 0;
    font-size: 14px;
    line-height: 48px;
    font-weight: normal;
    letter-spacing: 0;
    padding-right: 48px;
    background: url('/img/svg/right_slim.svg') no-repeat;
    background-size: 7px;
    background-position: right center;
    -o-transition: opacity .3s;
    -ms-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    position: absolute;
    top: 100%;
    margin-top: 0;
    left: 0;
    max-width: 100%;
}
#custom_carousel a.slider_button:hover {
    opacity: 0.7;
}
.carousel_navbar {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
}
.navbar_container:before {
    display: block;
    content: "";
    position: absolute;
    left: -20px;
    right: -20px;
    height: 1px;
    background-color: #fff;
    opacity: 0.4;

}
.carousel_navbar ul {
    padding: 0;
    margin: 0 63px 0 0;
    list-style-type: none;
    position: relative;
    float: left;
}
.carousel_navbar ul:after {
    display: block;
    position:absolute;
    right: -1px;
    top: 9px;
    bottom: 6px;
    width: 1px;
    background: #fff;
    opacity: 0.4;
    content: "";
}
.carousel_navbar ul li {
    width: 20%;
    float: left;
}
.carousel_navbar ul a {
    display: block;
    height: 87px;
    font-size: 12px;
    line-height: 18px;
    color: #fff !important;
    -o-transition: opacity .3s;
    -ms-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    padding-right: 25px;
    padding-left: 60px;
    position:relative;
    font-family: 'BentonSans-Book', Arial;
}
.carousel_navbar ul a:hover {
    opacity: 0.7;
}
.carousel_navbar ul a.active {
    opacity: 1;
    cursor: default;
}
.navbar_container {
    max-width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
    position:relative;
}
.home_slider {
    position: relative;
    overflow: hidden;
}
.main_slider {
    white-space: nowrap;
    position: relative;
    overflow: hidden;
}
.main_slider > .item {
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
    width: 100%;
}
.carousel_navbar ul li:first-child a {
    margin-left: -35px;
    padding-left: 95px;
}
.carousel_navbar .slide_number {
    display: block;
    position: absolute;
    width: 39px;
    height: 39px;
    left: 0;
    top: 24px;
    text-align: center;
    padding: 11px 0;
}
.carousel_navbar ul li:first-child .slide_number {
    left: 35px;
}
.carousel_navbar .slide_number:before {
    display: block;
    position:absolute;
    content: "";
    width: 29px;
    height: 29px;
    border: 1px solid #ffffff;
    border-radius: 2px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 5px;
    left: 5px;
}
.carousel_navbar .slide_description {
    display: table;
    width: 100%;
    height: 100%;
}
.carousel_navbar .slide_description span {
    display: table-cell;
    vertical-align: middle;
}
.carousel_navbar ul:before {
    position: absolute;
    display: block;
    top: -3px;
    height: 5px;
    content: "";
    background: #fff;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    width: 20%;
    right: auto;
}
.go_to_next {
    display: block;
    width: 15px;
    height: 41px;
    float: left;
    margin-left: -34px;
    margin-top: 23px;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}
.go_to_next:hover {
    opacity: 0.8;
}
.go_to_next span {
    display:block;
    height: 9px;
    margin-top: 7px;
    background: url('/img/svg/down_white.svg') no-repeat;
    background-size: 15px auto;
    background-position: center;
}
.go_to_next span:first-child {
    margin: 0;
}
.slider_button:hover {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.87);
}
.carousel_navbar ul.pos1:before {
    left: -35px;
    min-width:20%;
    width: auto;
    right: 80%;
}
.carousel_navbar ul.pos2:before {
    left: 20%;
}
.carousel_navbar ul.pos3:before {
    left: 40%;
}
.carousel_navbar ul.pos4:before {
    left: 60%;
}
.carousel_navbar ul.pos5:before {
    left: 80%;
}
.slider_button:focus {
    text-decoration: none;
}

.slider_button .fa-chevron-right {
    padding-left: 38px !important;
    font-weight: normal;
}

/*home page TRAVELERS' FAVORITES block styles*/
.block_title {
    width: 100%;
    clear: both;
}

.block_title h3,
.block_title_home h1{
    color: #858585;
    font-family: 'BentonSans-Bold', Arial;
    font-size: 20px;
    margin: 30px 0;
    font-weight: 900;
}

.travelers_items, .news_items {
    background: #fff;
    padding: 15px;
    position: relative;
}

.news_items img {
    width: 100%
}

.travelers_items h4 {
    color: #858585;
    font-family: 'BentonSans-Bold', Arial;
    font-size: 18px;
    line-height: 22px;
    height: 44px;
    margin-top: 5px;
    overflow: hidden;
    font-weight: 900;
    margin-bottom: 10px !important;
}
.travelers_items h4 a {
    display: inline-block;
    vertical-align: top;
    color: inherit;
}
.last_minute {
    font-family: 'BentonSans-Regular', Arial;
    font-size: 15px;
    text-decoration: none;
    color: #fff;
    background: #8d191b;
    padding: 5px 10px;
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 900;
}

.best_price {
    font-family: BentonSans_black, Arial;
}

.travelers_items .top {
    position: relative;
    overflow: hidden;
    padding-bottom: 65%
}

.travelers_items .top img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

/*.usefull_information .travelers_items:hover .bottom{ transition-duration: 1s;transform: translate(0, -150px);  background:#fff; width:100%; overflow: visible}*/

.reviews_block {
    padding-top: 10px
}

.stars {
    display: inline-block;
    float: left;
}

.reviews {
    font-size: 18px;
    font-family: BentonSans_book_regular, Arial;
    padding-top: 3px
}

.travelers_content .reviews {
    padding-top: 0px;
    font-size: 16px;
}

.current_tour_info {
    clear: both;
    position: relative;
    min-height: 135px;
}

.duration {
    clear: both;
}

.small_group, .tour_date, .duration, .BB, .age, .tour_price_block, .tour_details .tour_date {
    padding-left: 35px;
    padding-top: 10px;
    font-size: 15px;
}

.similar_tour_bottom .duration {
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 5px;
}

.similar_tour_bottom .price_block {
    position: relative
}

.similar_tour_bottom .sale_price, .similar_tour_bottom .price {
    padding-top: 10px !important;
}

.similar_tour_bottom .price_block .price:before {
    top: 60%
}
.current_tour_info > div {
    position: relative;
}
.small_group:before,
.tour_date:before,
.duration:before,
.BB:before,
.age:before,
.tour_price_block:before {
    content: "";
    width: 30px;
    height: 18px;
    position: absolute;
    left: 0px;
    top: 11px;
    background-position: center !important;
}

.small_group:before {
    background: url('/img/svg/group_blue.svg') no-repeat;
    background-size: auto 18px;
}
.tour_date:before {
    background: url('/img/svg/date_blue.svg') no-repeat;
    background-size: auto 18px;
}
.duration:before {
    background: url('/img/svg/time_blue.svg') no-repeat;
    background-size: auto 24px;
}
.BB:before {
    background: url('/img/svg/gear_blue.svg') no-repeat;
    background-size: auto 27px;
}
.age:before {
    background: url('/img/svg/age_blue.svg') no-repeat;
    background-size: auto 22px;
}
.tour_price_block:before {
    background: url('/img/svg/sale_blue.svg') no-repeat;
    background-size: auto 20px;
}
.tour_details .small_group:before {
    top: 19px;
    background: url('/img/svg/group.svg') no-repeat;
    background-size: auto 18px;
}
.tour_details .tour_date:before {
    top: 19px;
    background: url('/img/svg/date.svg') no-repeat;
    background-size: auto 18px;
}
.tour_details .duration:before {
    top: 19px;
    background: url('/img/svg/time.svg') no-repeat;
    background-size: auto 24px;
}
.tour_details .BB:before {
    top: 19px;
    background: url('/img/svg/gear.svg') no-repeat;
    background-size: auto 27px;
}
.tour_details .age:before {
    top: 19px;
    background: url('/img/svg/age.svg') no-repeat;
    background-size: auto 22px;
}
.tour_details .tour_price_block:before {
    top: 19px;
    background: url('/img/svg/sale.svg') no-repeat;
    background-size: auto 20px;
}







.price_block {
    padding-top: 5px;
    min-height: 60px;
}

.price_block .regular_price:before {
    display: none !important;
}

.price_block .regular_price {
    line-height: 55px;
    padding: 0px !important;
}

.price_block .price, .price_block .regular_price {
    font-size: 18px;
    font-family: 'BentonSans-Bold', Arial;
    color: #8d191b;
    position: relative;
    display: inline-block;
    padding: 0 10px;
    font-weight: 900;
}

.price_block .price:before {
    content: "";
    position: absolute;
    top: 40%;
    left: 0;
    margin-top: 1px;
    height: 2px;
    width: 100%;
    background-color: #8d191b;
}

.price_block .sale_price {
    font-size: 20px;
    font-family: 'BentonSans-Bold', Arial;
    color: #2f7dc9;
    font-weight: 900;
}

.book_now {
    width: 100%;
    border: 1px solid #2f7dc9;
    border-radius: 0;
    font-size: 20px;
    font-family: 'BentonSans-Regular', Arial;
    color: #2f7dc9;
    padding: 5px 0;

}

.book_now:hover {
    color: #fff;
    background: #2f7dc9;
    border: 1px solid #2f7dc9;
}

.book_now:focus {
    border: 1px solid #2f7dc9;
    color: #2f7dc9;
    background: #fff
}

.travelers_content .col-xs-6 {
    margin-bottom: 30px;
}

.view_all_tours {
    float: left;
    width: 100%;
    text-align: center;
    padding-bottom: 30px;
}

.view_all_team {
    width: 100%;
    text-align: center;
    padding-top: 20px;
}

.view_all_tours span, .view_all_team span {
    font-family: 'BentonSans-Bold', Arial;
    font-size: 14px;
    color: #5e5e5e;
    font-weight: 900;
    padding-left: 10px;
}

.view_all_tours .fa, .view_all_team .fa {
    font-size: 10px;
}

.view_all_tours .fa-circle:before, .view_all_team .fa-circle:before {
    color: #5e5e5e;
}

a:focus {
    text-decoration: none !important;
    outline: none !important;
}

.book_with_Arara {
    color: #2f7dc9;

    clear: both;
    font-size: 0;
}

.disable_scroll {
    overflow: hidden;
}

.book_with_Arara .col-lg-4 {
    padding: 0 15px;
    font-size: 1rem;
    float: none;
    display: inline-block;
    vertical-align: top;
}

.book_with_Arara .col-lg-4 div {
    font-size: 15px;

    margin: 10px 0 10px 70px;
    position: relative;
}
.tour_sprite_title {
    border-left: 2px solid #2f7dc9;
    padding-left: 5px;
    height: 60px;
    text-transform: uppercase;
}
.book_with_Arara .col-lg-4 div:before {
    content: " ";
    width: 50px;
    height: 50px;
    position: absolute;
    left: -70px;
    background-repeat: no-repeat !important;
}

.book_with_Arara .distance:before {
    background: url('/img/svg/locations.svg');
    background-size: auto 40px;
    background-position: right top;
}
.book_with_Arara .drivers_icon:before,
.book_with_Arara .arara_guoides:before {
    background: url('/img/svg/guide.svg');
    background-size: auto 40px;
    background-position: right top;
}

.book_with_Arara .licensed_transport:before {
    background: url('/img/svg/car.svg');
    background-size: auto 50px;
    background-position: right -5px;
}

.book_with_Arara .hotels:before {
    background: url('/img/svg/hotel.svg');
    background-size: auto 40px;
    background-position: right top;
}

.book_with_Arara .high-quality:before {
    background: url('/img/svg/price.svg');
    background-size: auto 42px;
    background-position: right -1px;
}

.book_with_Arara .satisfied_clients:before {
    background: url('/img/svg/hand.svg');
    background-size: auto 46px;
    background-position: right -3px;
}

.book_with_Arara .Balanced_icon:before {
    background: url('/img/svg/ksherq.svg');
    background-size: auto 46px;
    background-position: right -3px;
}

.book_with_Arara .bike-2_icon:before {
    background: url('/img/svg/bike3.svg');
    background-size: auto 40px;
    background-position: right top;
}

.book_with_Arara .bike-3_icon:before {
    background: url('/img/svg/bike.svg');
    background-size: auto 50px;
    background-position: right -11px;
}

.book_with_Arara .bike-routes_icon:before {
    background: url('/img/svg/bike2.svg');
    background-size: auto 42px;
    background-position: right -1px;
}

.book_with_Arara .biking_icon:before {
    background: url('/img/svg/shlem.svg');
    background-size: auto 40px;
    background-position: right top;
}

.book_with_Arara .cooking_icon:before {
    background: url('/img/svg/cooking.svg');
    background-size: auto 42px;
    background-position: right -1px;
}


.book_with_Arara .guide_icon:before {
    background: url('/img/svg/guide2.svg');
    background-size: auto 42px;
    background-position: right -1px;
}

.book_with_Arara .guides_icon:before {
    background: url('/img/svg/guide3.svg');
    background-size: auto 42px;
    background-position: right -1px;
}

.book_with_Arara .national-dishes_icon:before {
    background: url('/img/svg/dinner.svg');
    background-size: auto 42px;
    background-position: right -1px;
}

.book_with_Arara .organic-food_icon:before {
    background: url('/img/svg/banjarexen.svg');
    background-size: auto 40px;
    background-position: right top;
}

.book_with_Arara .performances_icon:before {
    background: url('/img/svg/dance.svg');
    background-size: auto 46px;
    background-position: right -3px;
}

.book_with_Arara .shepherds-in-the-mountains_icon:before {
    background: url('/img/svg/hoviv.svg');
    background-size: auto 40px;
    background-position: right top;
}

.book_with_Arara .technician-guid_icon:before {
    background: url('/img/svg/guide4.svg');
    background-size: auto 40px;
    background-position: 14px top;
}

.book_with_Arara .unesko_icon:before {
    background: url('/img/svg/unesco.svg');
    background-size: auto 48px;
    background-position: right -4px;
}

.arara_buttons {
    text-align: center;
    clear: both;
    padding-top:15px;
}

/*.btn_reviews, .more_about_arara{ width: 100%;max-width:300px;border: 1px solid #7d7d7d;border-radius: 0;font-size:22px;font-family: 'BentonSans-Regular';color: #7d7d7d;margin: 25px; padding:15px;}*/
.btn_reviews, .more_about_arara {
    width: 100%;
    max-width: 300px;
    border: 1px solid #7d7d7d;
    font-family: 'BentonSans-Book', Arial;
    margin: 15px;
    padding: 10px;
    color: #7d7d7d;

}
.arara_buttons .btn_reviews,
.arara_buttons .more_about_arara {
    margin-bottom: 0;
}
.block_title_home h3 {
    margin-top: 15px;
}
.block_title_home h1 {
    margin-top: 0;
}

.btn_reviews:hover, .more_about_arara:hover {
    background: #7d7d7d;
    color: #fff
}

.adventur_text {
    font-size: 16px;
}

.adventur_sliders_title {
    font-family: 'BentonSans-Bold', Arial;
    font-size: 16px;
    color: #fff;
    width: 100%;
    background: #307eca;
    padding: 4px 0 4px 15px;
    text-transform: uppercase;
    line-height: 22px;
    font-weight: 900;
}

#adventure_carousel .small_group:before, #adventure_carousel .tour_date:before, #adventure_carousel .duration:before, #adventure_carousel .BB:before {
    left: 14px;
}

#classic_carousel .small_group:before, #classic_carousel .tour_date:before, #classic_carousel .duration:before, #classic_carousel .BB:before {
    left: 14px;
}

#arm_geo_carousel .small_group:before, #arm_geo_carousel .tour_date:before, #arm_geo_carousel .duration:before, #arm_geo_carousel .BB:before {
    left: 14px;
}

.adventure_block_arrows {
    position: absolute;
    top: 2px;
    right: 25px;
}
.adventure_block_arrows a>span{
    cursor: pointer;
}
.adventure_block_arrows .arrow-left {
    width: 20px;
    transform: rotate(180deg);
    height: 30px;
    display: block;
    background-position: -260px -93px;
}

.adventure_block_arrows .arrow-right {
    width: 20px;
    /* transform: rotate(-90deg); */
    height: 30px;
    display: block;
    background-position: -259px -96px;
}

.custom_carousel_control {
    float: left;
}

.adventur_sliders {
    margin-top: 30px;
}

.usefull_information {
    margin-bottom: 30px
}

/*
.usefull_information .travelers_items .bottom {
    margin-top: 20px;
    max-height: 220px;
    min-height: 220px;
    overflow: hidden;
}
*/

.usefull_information .travelers_items .bottom span {
    color: #307eca;
    font-size: 17px;
    font-style: italic;
    font-family: 'BentonSans-Regular', Arial;
}

.usefull_information .travelers_items .bottom h4 {
    margin-top: 15px;
    margin-bottom: 15px;
    height: auto;
    line-height: 22px;
}

.usefull_text {
    font-size: 16px;
    line-height: 22px;
}

/*.usefull_information .travelers_items .bottom.hasBeen500ms {
    overflow: visible;
}*/

#news_comment .inner_left .form-group, #feedback_form .inner_left .form-group {
    margin-bottom: 20px;
}

.navbar-inverse.side-collapse.in #navbar .dropdown:nth-last-child(-n+3) .dropdown-menu {
    left: auto;
    right: -22px;
    margin-left: 0;
}
.navbar-inverse.side-collapse.in #navbar .last .dropdown-menu {
    left: auto;
    right: -30px !important;
    margin-left: 0;
}
.navbar-inverse.side-collapse.in #navbar .dropdown:nth-last-child(-n+3) .dropdown-menu:before {
    right: 25px;
    left: auto;
}

/*.navbar-inverse.side-collapse.in #navbar .dropdown:nth-last-child(2) .dropdown-menu:after {
    right: 13px;
    left: auto;
}*/
.hotels_listing{
    margin-top: 30px;
}
.hotels_listing .col-xs-6 img {
    width: 100%
}

.hotels_listing_title {
    padding-left: 15px;
}

/*tour inner page styles*/

.tour_banner {
    position: relative;
}

/*.tour_banner img {width: 100%;position: absolute;top: 0;left: 0;right: 0;bottom: 0;margin: auto;}*/
.tour_title_block {
    position: absolute;
    top: 25%;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 1170px;
    padding: 0 15px;
}

.tour_title_block .tour_title {
    color: #fff;
    font-size: 50px;
    letter-spacing: 1px;
    font-family: 'BentonSans-Bold', Arial;
    max-width: 700px;
    font-weight: 900;
}

.tour_title_block .tour_subtitle {
    color: #fff;
    font-size: 24px;
    letter-spacing: 1px;
    font-family: 'BentonSans-Book', Arial
}

.tour_banner_bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.tour_banner_bottom .banner_review {
    position: absolute;
    top: -60px;
    max-width: 1170px;
    left: 0;
    right: 0;
    margin: auto;
}

.tour_details {
    padding: 15px 0px;
    width: 100%;
    background: rgba(0, 0, 0, 0.71);
    font-size: 16px;
    color: #fff;
}
.tour_details .tour_sprite:first-child {
    margin-top: -10px;
}
.banner_review {
    padding: 0 15px;
}

.banner_review .fa-star {
    color: #fff;
    font-size: 25px;
    padding-left: 0;
}
.banner_review .reviews_block {
    max-width: 330px;
}

.banner_review .reviews_block .stars {
    /*margin-left: 20px;*/
}

.banner_review .reviews {
    color: #fff;
    padding-top: 1px;
    margin-left: 15px;
    float: left;
}

.tour_price_block .sale_price {
    display: inline-block;
    padding-right: 5px;
}

.tours_first_block .right img {
    width: 100%
}

.tour_details .duration,
.tour_details .BB,
.tour_details .age,
.tour_details .tour_date,
.tour_details .small_group,
.tour_details .tour_price_block {
    padding-left: 40px;
    line-height: 35px;
    position: relative;
}


.tour_price_block > span {
    display: inline-block;
    padding-right: 5px;
}

.tour_price_block .price {
    color: #bb0000;
    display: inline-block;
    position: relative;
    padding: 0px 5px;
}

.tour_price_block .price:before {
    content: "";
    position: absolute;
    top: 45%;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #bb0000;
}

.tour_details .book_now {
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    font-family: 'BentonSans-Book', Arial;
}

.tour_book_now {
    margin-top: 45px;
}

.tours_first_block {
    padding: 20px 0;
}

.tours_first_block h3 {
    color: #858585;
    font-family: 'BentonSans-Bold', Arial;
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 0;
    font-weight: 900;
}

.single_tour_details span {
    font-size: 16px;
    padding-left: 30px;
    position: relative;
    display: inline-block;
    margin: 5px 0;
    letter-spacing: 1px;
    padding-right: 60px;
    color: #565c64;
    background: url('/img/svg/round_checked.svg') no-repeat;
    background-size: 22px;
    background-position: top left;
    width: 100%;
}



.tour_menu {
    position: relative;
    height: 46px;
}
.tm_container {
    background: #2f7dc9;
    padding: 5px 0;
    z-index: 9999999;
    height: 46px;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    -o-transition: opacity .3s, top .3s;
    -ms-transition: opacity .3s, top .3s;
    -moz-transition: opacity .3s, top .3s;
    -webkit-transition: opacity .3s, top .3s;
    transition: opacity .3s, top .3s;
}

.tm_container.showed {
    opacity: 1;
}
.tm_container.fixed_block {
    position: fixed;

}
.tm_container.fixed_block.inactive {
    top: -46px;
}
#all_tours .tour_menu {
    height: 46px;
    z-index: 1;
    background: #2f7dc9;
    padding: 5px 0;
    position: relative;
    overflow: hidden;
}
#all_tours .landing_epilogue {
    padding-top: 0;
}
#all_tours .custom_pagination {
    padding-bottom: 30px;
}
.tour_menu ul {
    margin-bottom: 0px;
    padding: 0px
}

.tour_menu .tour_menu_inner_mobile a {
    color: #fff;
    font-size: 14px;
    margin-right: 20px;
    position: relative;
    padding: 5px 0;
}

.tour_menu a:hover, .tour_menu a:focus {
    text-decoration: none
}

.tour_menu_inner {
    max-width: 1140px;
    margin: 0 auto;
}

.tour_menu_inner .active a {
    text-decoration: none;
}

.tour_menu_book_now {
    display: inline-block;
    float: right;
}

.tour_menu_book_now .book_now {
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    background: transparent;
    border: 1px solid #fff;
    min-width: 106px;
    text-align:center;
    padding: 3px 20px;
    margin-top: 2px;
}

.tour_menu .active:after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 0;
    height: 5px;
    width: 100%;
    background-color: #9dccfa;
}

.tour_menu_inner_mobile {
    display: inline-block;
    position: relative
}

.tour_menu_inner_mobile_inner {
    position: relative
}

.tour_menu_fix_top {
    position: fixed;
    top: 0
}
.tour_accordions {
    clear: both;
    padding-top: 15px;
}

.tour_accordions .accardion_header {
    position: relative;
    background: #e1e1e1;
    padding: 10px 15px;
}

.tour_accordions .accardion_header .title {
    font-size: 18px;
    line-height: 30px;
    margin: 0;
    color: #858585;
    font-family: 'BentonSans-Bold', Arial;
    display: inline-block;
    font-weight: 900;
}

.tour_accordions .download_brochure {
    background: #2f7dc9;
    color: #fff;
    font-size: 16px;
    font-family: 'BentonSans-Book', Arial;
    padding: 8px 12px;
    margin-top: 20px;
}
#itinerary {
    padding-bottom: 30px;
}
.tour_accordions .col-lg-6.col-md-6.col-sm-6 {
    padding: 0px
}

.tour_accordions .right img {
    width: 100%
}

.tour_accordions .glyphicon.glyphicon-chevron-right:before, .tour_accordions .glyphicon.glyphicon-chevron-left:before {
    height: 40px;
    width: 20px;
    top: 0;
}

.current_day_description {
    padding-right: 15px;
    /*margin-top: -10px*/
}

.current_day_text {
    font-size: 16px;
    color: #565c64;
    line-height: 30px;
}

.lng-ru .current_day_text .t-overnight span, .lng-ru .current_day_text .t-duration span, .current_day_text .t-meels span {
    font-family: "Arial";
    font-weight: 900;
}

.current_day_text .t-overnight span, .current_day_text .t-duration span, .current_day_text .t-meels span {
    font-family: 'BentonSans-Bold', Arial;
    font-weight: 900;
}

.tour_accordions .custom_accordion {
    display: block;
    /*padding-top: 3px;*/
    margin-bottom: 0px;
    font-size: 18px;
    color: #858585;
    font-family: 'BentonSans-Bold', Arial;
    border: none;
    text-align: left;
    outline: none;
    transition: 0.4s;
    cursor: pointer;
    font-weight: 900;
}

.tour_accordions .accardion_item.active .custom_accordion {
    color: #2f7dc9
}

.tour_accordions .first .expand_all {
    position: absolute;
    right: 0;
    margin: 0;
    top: 0;
    font-size: 16px;
    font-family: 'BentonSans-Book', Arial;
    color: #2f7dc9;
}

.tour_accordions .accardion_item {
    position: relative;
    padding: 0px 0px 20px 40px;
}

.tour_accordions .accardion_item.last {
    padding-bottom: 30px;
}

.tour_accordions .accardion_item.first .custom_accordion_content {
    margin-left: -25px;
    color: #565c64;
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
}
#itinerary .custom_accordion_content .left {
    padding-left: 15px;
    padding-right: 15px;
}
#itinerary .custom_accordion_content .right {
    padding-left: 50px;
}

#itinerary .current_day_text > div {
    display: inline-block;
    vertical-align: top;
    padding-right: 30px;
}
#itinerary .current_day_text > div.current_day_description {
    width: auto;
    padding-right: 0;
    text-align: justify;
    display: block;
}
.tour_accordions .accardion_item.first .custom_accordion_content p {
    margin: 0;
}
.tour_accordions .active.accardion_item.first .custom_accordion_content {
    padding-top: 20px;
    padding-bottom: 0;
}
.tour_accordions .accardion_item:before {
    position: absolute;
    content: "";
    border-left: 1px dashed #2f7dc9;
    width: 0;
    padding-top: 50px;
    bottom: -15px;
    left: 15px;
    top: 15px;
}

.tour_accordions .accardion_item.first:before, .tour_accordions .accardion_item.last:before {
    border: none !important;
}

.active.accardion_item .custom_accordion_content {
    max-height: 9999px;
    padding-top: 30px;
    padding-bottom: 10px;
}
.active.accardion_item#tour_reviews .custom_accordion_content{
    padding-bottom: 0px;
}
.active.accardion_item .custom_accordion_content .current_tour_reviews:first-child{
    margin-top: 0px;
}
.tour_accordions .accardion_item .custom_accordion:after {
    content: "";
    position: absolute;
    left: -40px;
    top: 0px;
    width: 31px;
    height: 30px;
}
.tour_accordions .accardion_item .custom_accordion:after {
    background: url('/img/svg/klor_plus.svg') no-repeat;
    background-size: 21px;
    background-position: top center;
}
.tour_accordions .accardion_item.first .custom_accordion:after {
    background: url('/img/svg/info.svg') no-repeat !important;
    background-size: 21px !important;
    background-position: top center!important;
}

.tour_accordions .accardion_item.second .custom_accordion:after {
    background: url('/img/svg/map.svg') no-repeat !important;
    background-size: 21px !important;
    background-position: top center !important;
}

.tour_accordions .accardion_item.last .custom_accordion:after {
    background: url('/img/svg/flag.svg') no-repeat !important;
    background-size: 21px !important;
    background-position: top center !important;
}

.tour_accordions .accardion_item.active .custom_accordion:after {
    background: url('/img/svg/klor_minus.svg') no-repeat;
    background-size: 23px;
    background-position: top center;
}
.tour_accordions .accardion_item.first.active .custom_accordion:after,
.tour_accordions .accardion_item.last.active .custom_accordion:after,
.tour_accordions .accardion_item.second.active .custom_accordion:after {
    background-size: 23px !important;
    background-position: top center !important;
}





.accardion_item .custom_accordion_content {
    overflow: hidden;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height .5s, padding-top 1s;
    -moz-transition: max-height .5s, padding-top 1s;
    transition: max-height .5s, padding-top 1s;
}


.expand_all {
    display: block;
    cursor: pointer;
    font-size: 12px;
    line-height: 30px;
    color: #2f7dc9;
}
.expand_all:after {
    display: block;
    content: "";
    margin-left: 18px;
    width: 16px;
    height: 30px;
    float: right;
    background: url(/img/svg/down_black.svg) no-repeat;
    background-size: 18px auto;
    background-position: center 7px;
    opacity: 0.48;
}
.tour_second_accordions .custom_accordion {
    font-size: 18px;
    color: #858585;
    font-family: 'BentonSans-Bold', Arial;
    background: #e1e1e1;
    padding: 15px;
    position: relative;
    margin: 0px;
    line-height: 20px;
    font-weight: 900;
}
.tour_second_accordions #tour_reviews .custom_accordion {
    margin-bottom: 0px;
}
.tour_second_accordions .accardion_item .custom_accordion:after {
    content: "";
    position: absolute;
    right: 15px;
    top: 16px;
    width: 16px;
    height: 16px;
    float: right;
    background: url(/img/svg/down_black.svg) no-repeat;
    background-size: 18px auto;
    background-position: center;
    opacity: 0.48;
}

.tour_second_accordions .accardion_item.active .custom_accordion:after,
.expand_all.hideAll:after,
#itinerary .accardion_item.active .custom_accordion:before {
    -webkit-transform: scaleY(-1);
    -moz-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    transform: scaleY(-1);
}


.tour_second_accordions .accardion_item {
    padding-bottom: 20px
}
#accommadation .accommadation .bottom {
    margin-top: 0px;
}
#book_arara .btn_reviews, #book_arara .more_about_arara{
    margin-bottom: 0px;
}


.tour_second_accordions .accardion_item:last-child .custom_accordion_content {
    margin-bottom: 0px;
    padding-bottom:0px;
}
#accommadation.active.accardion_item .custom_accordion_content{
    padding: 0px;
}

.tour_second_accordions .accardion_title {
    color: #2f7dc9;
    font-family: 'BentonSans-Bold', Arial;
    font-weight: 900;
}
.dayly_slider .slider_inner {
    position: relative;
    overflow: hidden;
    font-size: 0;
    white-space: nowrap;
}
.dayly_slider .slider_inner .slide_block {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}
.dayly_slider .slick-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 15%;
    color: #fff;
    background-color: rgba(0,0,0,0);
    filter: alpha(opacity=50);
    opacity: .5;
    border: none;
    z-index: 2;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}
.dayly_slider .slick-prev {
    left: 0;
    background-image: -webkit-linear-gradient(left,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);
    background-image: -o-linear-gradient(left,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);
    background-image: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.5)),to(rgba(0,0,0,.0001)));
    background-image: linear-gradient(to right,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
    background-repeat: repeat-x;
}

.dayly_slider .slick-next {
    right: 0;
    background-image: -webkit-linear-gradient(left,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
    background-image: -o-linear-gradient(left,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
    background-image: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.0001)),to(rgba(0,0,0,.5)));
    background-image: linear-gradient(to right,rgba(0,0,0,.0001) 0,rgba(0,0,0,.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
    background-repeat: repeat-x;
}

.dayly_slider .slick-arrow:focus, .dayly_slider .slick-arrow:hover {
    color: #fff;
    text-decoration: none;
    filter: alpha(opacity=90);
    outline: 0;
    opacity: .9;
}
.dayly_slider .slick-arrow:before {
    content: "";
    position: absolute;
    background-size: cover;
    height: 60px;
    width: 30px;
    top: -20px;
    left: 50%;
    margin-left: -15px;
    top: 50%;
    margin-top: -30px;
}
.dayly_slider .slick-prev:before {
    background: url(/img/left_arrow.png) no-repeat;
    background-size: 20px auto;
    background-position: center;
}
.dayly_slider .slick-next:before {
    background: url(/img/right_arrow.png) no-repeat;
    background-size: 20px auto;
    background-position: center;
}
.dayly_slider .slider_description {
    text-align: center;
    padding-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #787c7f;
}
.tour_second_accordions .first .left {
    border-right: 1px solid #c8c8c8
}

.tour_second_accordions .first ul {
    padding-left: 0;
    margin-bottom: 0px;
}

.tour_second_accordions .first li {
    list-style: none;
    font-size: 16px;
    color: #565c64;
    position: relative;
    padding-left: 40px
}

.tour_second_accordions .first li:before {
    content: "";
    position: absolute;
    left: 13px;
    top: 5px;
    width: 12px;
    height: 12px;
}

.tour_second_accordions .first .left li:before {
    background: url('/img/svg/checked_blue.svg') no-repeat;
    background-size: 12px auto;
    background-position: left center;
}

.tour_second_accordions .first .right li:before {
    background: url('/img/svg/close_blue.svg') no-repeat;
    background-size: 8px auto;
    background-position: left center;
}

.current_tour_reviews .accardion_title {
    display: inline-block
}

.current_tour_reviews .stars {
    float: right;
    padding-top: 0;
}

.current_tour_reviews .fa-star {
    color: #397fba;
    font-size: 25px;
    padding-left: 3px;
}

.tour_reviews_text, .accommadation_text {
    font-size: 16px;
    color: #565c64;
    letter-spacing: 1px;
}

.tour_reviews_read_more {
    text-align: right;
}

.tour_reviews_read_more a {
    color: #397fba;
    font-size: 16px;
}

.load_more_reviews {
    text-align: center;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
}

.load_more_reviews a {
    color: #2f7dc9;
    font-size: 17px;
    font-family: 'BentonSans-Bold', Arial;
    display: inline-block;
    vertical-align: middle;
    font-weight: 900;
}

.load_more_reviews a:before {
    content: "";
    position: relative;
    width: 41px;
    height: 11px;
    background: url('/img/svg/keter.svg') no-repeat;
    background-size: auto 10px;
    background-position: right top;
    display: inline-block;
    margin-right: 4px;
}

.accommadation .tour_hottels .top img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.accommadation .tour_hottels .top {
    position: relative;
    overflow: hidden;
    padding-bottom: 55%;
    clear: both;
    margin-top: 10px;
}
.accommadation_top .row{
    font-size: 0;
}
.accommadation_top .row .col-lg-4.col-md-4.col-sm-4 {
    float: none;
    display: inline-block;
    vertical-align: top;
}
#accommadation .accommadation_top .col-xs-6 {
    margin-top: 30px;
}


.accommadation .hotel_star {
    font-size: 18px;
    color: #2f7dc9;
    font-family: 'BentonSans-Bold', Arial;
    margin: 0;
    float: left;
    padding-left: 5px;
    position: relative;
    margin-top:-4px;
    font-weight: 900;
}

.accommadation .accommadation_top {
    padding-bottom: 10px;
    float: left;
    width: 100%;
}

.accommadation .accommadation_bottom {
    float: left;
    width: 100%;
}

.accommadation .bottom {
    margin-top: 25px;
}

.trip_map img {
    width: 100%
}

.packing_checklist_item h4 {
    color: #2f7dc9;
    font-family: 'BentonSans-Bold', Arial;
    margin: 0px;
    font-weight: 900;
}

.packing_checklist_item .packing_checklist_item_text {
    font-size: 16px;
    color: #565c64;
    letter-spacing: 1px;
}

.packing_checklist_item {
    padding: 15px 0
}

.packing_checklist_item:first-child {
    padding-top: 0px;
}

.packing_checklist_item:last-child {
    padding-bottom: 0px;
}

#essential_information ul {
    padding: 0;
    margin-bottom: 0px;
}

#essential_information li {
    list-style: none;
    font-size: 16px;
    color: #2f7dc9;
    position: relative;
    padding-left: 25px;
    padding-bottom: 5px;
}

#essential_information li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    width: 20px;
    height: 20px;
    background: url('/img/svg/exit_blue.svg') no-repeat;
    background-size: auto 15px;
    background-position: -1px 2px;
}
#guaranteed_tour_dates table {
    margin-bottom: 0px
}

#guaranteed_tour_dates td {
    vertical-align: middle;
}
#guaranteed_tour_dates .load_more_reviews {
    padding-top: 30px;
    margin-bottom: 0;
}

#guaranteed_tour_dates tr td:nth-last-child(2) {
    vertical-align: top;
}

#guaranteed_tour_dates tr td:nth-last-child(2) h4 {
    margin-top: 0px
}
.guaranteed_duration {
    position: relative;
    text-align: center;

}
.fl {
    float: left;
}
.fr {
    float: right;
}
.guaranteed_duration:before {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    content: "";
    width: 2px;
    background-color: #ddd;
    left: 50%;
    margin-left: -1px;
}
.guaranteed_duration span {
    width: 52px;
    height: 52px;
    border: 2px solid #dfdfdf;
    background-color: #f3f3f3;
    z-index: 2;
    border-radius: 50%;
    padding: 10px 0;
    display: inline-block;
    vertical-align: top;
    font-size: 12px !important;
    line-height: 14px;
    color: #787c7f !important;
    position: relative;
}
#guaranteed_tour_dates table h4, #guaranteed_tour_dates th {
    color: #2f7dc9;
    font-family: 'BentonSans-Bold', Arial;
    font-weight: 900;
}
#itinerary .accardion_header {
    margin-bottom: 20px;
}
#itinerary .accardion_item {
    position: relative;
    padding-bottom: 10px;
}
#itinerary .accardion_item .custom_accordion {
    line-height: 28px;
    padding-right: 45px;
    position: relative;
}
#itinerary .accardion_item .custom_accordion:before {
    position: absolute;
    content: "";
    right: 15px;
    top: 50%;
    margin-top: -7px;
    width: 14px;
    height: 14px;
    margin-right: 1px;
    background: url(/img/svg/down_black.svg) no-repeat;
    background-size: 16px auto;
    background-position: center;
    opacity: 0.48;
}
#itinerary .accardion_item.first {
    padding-bottom: 20px;
}
#itinerary .accardion_item .custom_accordion:after {
    top: 3px;
}
#itinerary .accardion_item.active .custom_accordion:after {
    top: 2px;
}
#itinerary .accardion_item.second.active .custom_accordion:after {
    top: 1px;
}
#itinerary .accardion_item:after {
    position: absolute;
    left: 40px;
    right: 0;
    height: 1px;
    content: '';
    bottom: 0;
    background: #e8e8e8;
    display: block;
}
.cb {
    display: block;
    clear: both;
}

#guaranteed_tour_dates span {
    font-size: 16px;
    color: #565c64;
}

#guaranteed_tour_dates .book_now {
    background: transparent;
    letter-spacing: 3px;
    font-size: 20px
}

#guaranteed_tour_dates .book_now:focus {
    color: #2f7dc9
}

#guaranteed_tour_dates .book_now:hover {
    background: #2f7dc9;
    border: 1px solid #fff;
    color: #fff
}
/*
.nodate_tour td.guaranteed_dates.and {
    padding-top: 26px;
}*/
/*
.nodate_tour td.guaranteed_dates.and span.guaranteed_dates {
    display: none;
}*/
.nodate_tour .guaranteed_dates.and h4 {
    font-size: 0;
    height: 3px;
    width: 60px;
    background: #2f7dc9;
    margin: 19px 0;
}

.tour_dates_mobile_inner.nodate_tour .top_row .col-xs-5.text-right {
    /*padding-top: 34px;*/
    padding-bottom: 0;
}
.tour_dates_mobile_inner.nodate_tour .top_row .col-xs-5.text-right .guaranteed_dates {
    /*display: none;*/
}
.tour_dates_mobile_inner.nodate_tour .top_row .col-xs-5.text-right h4 {
    font-size: 0 !important;
    height: 25px;
    padding-top: 15px;
}
.tour_dates_mobile_inner.nodate_tour .top_row .col-xs-5.text-right h4:after {
   display: inline-block;
    height: 2px;
    width: 40px;
    background: #2f7dc9;
    content: "";
    vertical-align: top;
}
.similar_tours {
    padding-bottom: 30px !important;
    font-size: 0;
}
.similar_tours .col-lg-4 {
    display: inline-block;
    vertical-align: top;
    float: none;
}
.similar_tours .similar_tours_title, #about_parent .similar_tours_title {
    color: #858585;
    font-family: 'BentonSans-Bold', Arial;
    margin-bottom: 30px;
    margin-top: 0px;
    font-weight: 900;
}

#book_arara .book_with_Arara .bottom:before {
    left: 15px
}

#guaranteed_tour_dates table {
    border-bottom: 1px solid #ddd;
}
#guaranteed_tour_dates #guaranteed_tour_dates_mobile {
    display: none
}

#guaranteed_tour_dates #guaranteed_tour_dates_mobile span {
    font-size: 16px;
    color: #565c64;
}

#guaranteed_tour_dates #guaranteed_tour_dates_mobile .guaranteed h4 {
    color: #2f7dc9
}

.tour_dates_mobile_inner {
    margin-top: 25px;
}

.tour_dates_mobile_inner:first-child {
    margin-top: 5px;
}
.tour_dates_mobile_inner .top_row {
    border: 2px solid #d7d7d7;
    position: relative;
    overflow: hidden;

}
.tour_dates_mobile_inner .top_row .col-xs-5 {
    padding: 18px 15px 0;
    height: 115px;
}
.tour_dates_mobile_inner .bottom_row {
    border-left: 2px solid #d7d7d7;
    border-right: 2px solid #d7d7d7;
    padding: 12px 0;
}
.tour_dates_mobile_inner .bottom_row *,
.tour_dates_mobile_inner .top_row .col-xs-5 * {
    font-size: 14px !important;
    line-height: 25px !important;
    margin: 0;
}
.tour_dates_mobile_inner .guaranteed_duration span {
    font-size: 10px !important;
    line-height: 12px;
    padding: 12px 0;
}
.tour_dates_mobile_inner .bottom_row * {
    display: inline-block;
    vertical-align: top;
}
.tour_dates_mobile_price {
    color: #2f7dc9;
    font-family: 'BentonSans-Bold', Arial;
    font-weight: 900;
}
.tour_dates_mobile_inner .guaranteed_duration {
    padding: 31px 0;
}
.book_now_mobile {
    display: block;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    background: #2f7dc9;
    color: #fff !important;
    padding: 5px;
}
#all_tours .tour_menu_inner_mobile {
    margin-top: 5px;
}

/*end tour inner page styles */

/*About us page styles*/
.story #about_parent h1 {
    margin-bottom: 0;
}

#story .news_title {
    padding-left: 15px;
}

.hotels_listing .item-title, .news_items .item-title {
    max-height: 65px;
    min-height: 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

#about_parent {
    font-size: 16px;
}

#about_parent h1, #about_parent h3 {
    font-family: 'BentonSans-Bold', Arial; /*text-transform:uppercase;*/
    font-weight: 900;
}

#about_parent h1 {
    font-size: 20px;
    color: #858585;
    margin-bottom: 0px;
}

#about_parent h3 {
    font-size: 17px;
    color: #397fba;
    margin: 0px;
}
.team_members_parent,
.our_guides{
    margin-left: -4px;
    margin-right: -4px;
    font-size: 0;
}
.team_members_parent, .our_guides {
    padding-top: 30px;
    clear: both;
}

.team_members_parent .team_members img, .about_our_guides_parent .guides img {
    width: 100%;
}

.team_members_parent .team_members, .about_our_guides_parent .guides {
    width: 20%;
    display: inline-block;
    vertical-align: top;
    padding: 0 4px 8px;
}

.team_members_parent .team_members.first, .about_our_guides_parent .guides.first {
    margin-left: 0
}

.team_members_parent .team_members.last, .about_our_guides_parent .guides.last {
    margin-right: 0
}

.member_position {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 20px;
    color: #ffffff;
    background-color: #2f7dc9;
    display: block;
    font-family: 'BentonSans-Bold', Arial;
    font-weight: 900;
    min-height: 72px;
    text-align: center;
    display: table;
    width: 100%;
}



.guides ul {
    padding: 0;
}

.guides ul li a {
    color: #2f7dc9;
    line-height: 30px;
}

.guides ul li {
    list-style: none;
    padding-left: 25px;
    position: relative;
    font-size: 15px
}

.guides ul li:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 5px;
    width: 30px;
    height: 25px;
    background-position: -204px -170px;
}

#about_parent .guide_reviews a {
    color: #2f7dc9;
    font-size: 17px;
}

#about_parent .guide_reviews h3{
    font-size: 17px;
    color: #858585;
    padding-bottom: 20px;
}
.legal_information:after {
    display: block;
    content: "";
    clear: both;
}
.legal_information .left {
    text-align: center
}

.legal_information .left div {
    text-align: left
}

.legal_information {
    padding-bottom: 30px;
}

#similar_tours {
    padding: 10px 15px 30px 15px
}

.similar_tours:before {
    display: none;
}

/*End About us page styles*/

/*Arara team page styles*/
#arara_team {
    margin-top: 0;
    font-size: 16px;
}

#arara_team h1, #arara_team h6 {
    font-family: 'BentonSans-Bold', Arial;
    letter-spacing: 1.2px;
    font-weight: 900;
}

#arara_team h1 {
    font-size: 20px;
    color: #858585;
    margin-bottom: 30px;
}

#arara_team h6 {
    font-size: 17px;
    color: #397fba;
    margin: 0px;
}

#arara_team .arara_team_members {
    margin-bottom: 30px;
    font-size: 0;
    /*float: left;*/
}
.arara_team_members .col-sm-4{
    float: none;
    font-size: 16px;
    display: inline-block;
    vertical-align: top;
    padding-top: 30px;
}

#arara_team .team_current_member .top {
    position: relative;
    overflow: hidden;
    padding-bottom: 95%
}

#arara_team .team_current_member .top img {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

#arara_team .member_profesion {
    padding: 20px 10px;
    background: #fff;
}

#arara_team .current_member_name {
    color: #565c64;
    font-family: 'BentonSans-Bold', Arial;
    font-weight: 900;
}
.current_member_name a {
    display: inline-block;
    vertical-align: top;
    color: inherit;
}
#arara_team .current_member_position {
    color: #2f7dc9;
    font-family: 'BentonSans-Bold', Arial;
    padding: 5px 0;
    min-height: 55px;
    font-weight: 900;
}

#arara_team .member_profesion .text #arara_team .member_profesion .text {
    color: #858585;
    height: 150px;
    letter-spacing: 1px;
    overflow: hidden;
    margin-bottom: 15px;
    position: relative;
}

#arara_team .members_social .fb {
    margin-right: 10px
}

#arara_team .arara_team_members.first {
    margin-top: 30px
}

/*End Arara team page styles*/
/*Contact Us page styles*/
#contact_us {
    margin-top: 30px;
    font-size: 17px;
    margin-bottom: 30px;
}

#contact_us h1, #contact_us h6 {
    font-family: 'BentonSans-Bold', Arial;
    letter-spacing: 1.2px;
    font-weight: 900;
}

#contact_us h1 {
    font-size: 20px;
    color: #858585;
    margin: 30px 0;
}

#contact_us h6 {
    font-size: 17px;
    color: #397fba;
}

#contact_us .contact_us_content h6 {
    font-size: 17px;
    color: #858585;
}

#contact_us .contact_info_inner .right {
    color: #2f7dc9
}

#contact_us .contact_us_content {
    padding-top: 15px;
}

#contact_us .contact_us_content .left {
    padding-left: 0px
}

#contact_us .contact_us_content .right {
    padding-right: 0px
}

#contact_us .contact_us_form {
    width: 100%;
    float: left;
    margin-top: 20px;
}

#contact_us .contact_us_form .form-group {
    padding: 0;
}

#contact_us .contact_us_form .left .form-group {
    margin-bottom: 20px;
}

#contact_us .contact_us_form label {
    font-size: 15px;
}

#contact_us .contact_us_form .form_submit {
    float: right;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px
}
#feedback .form-group.form_submit {
    margin-bottom: 0;
}


.g-recaptcha > div {
    margin-top: 20px;
}

/*End Contact Us page styles*/
/*feedbeack page styles*/

#feedback {
    font-size: 16px;
    margin-bottom: 30px;
}

#feedback h1, #feedback h6 {
    font-family: 'BentonSans-Bold', Arial;
    letter-spacing: 1.2px;
    font-weight: 900;
}

#feedback h1 {
    font-size: 20px;
    color: #858585;
    margin: 30px 0;
}

#feedback h6 {
    font-size: 17px;
    color: #397fba;
}

#feedback .feedback_text {
    letter-spacing: 2px;
    line-height: 30px;
}

#feedback .feedback_bottom {
    margin-top: 30px;
}

#feedback .feedback_bottom .left {
    padding-left: 0px
}

#feedback .feedback_bottom .left .inner_left.col-lg-6 {
    padding-right: 15px;
}

/*#feedback  .feedback_bottom .left .col-lg-6, .feedback_bottom .left .col-lg-6  .form-group{padding-left:0; padding-right:0px;}*/
.feedback_bottom .left .col-lg-6 .form-group {
    clear: both;
    padding-top: 0px;
}

.feedback_bottom .left label {
    font-weight: 300;
    margin-bottom: 7.5px;
}

.feedback_bottom .left label span {
    color: red;
}



#feedback .feedback_right_block {
    float: left;
    width: 100%;
    padding-bottom: 15px;
}

#feedback .feedback_right_block .feedback_ratig_left {
    float: left;
    width: 70%;
}

#feedback .feedback_right_block .feedback_ratig_right .fa-star {
    color: #397fba;
    font-size: 25px;
    padding-left: 3px;
}


#feedback .feedback_right_block .feedback_ratig_right .br-theme-fontawesome-stars .br-widget a {
    width: 23px;
    height: 25px;
    display: inline-block;
    vertical-align: top;
}
#feedback .feedback_right_block .feedback_ratig_right .br-theme-fontawesome-stars .br-widget a:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: url('/img/svg/star_blue_empty.svg') no-repeat;
    background-size: 100% auto !important;
    background-position: center top !important;
}

#feedback .feedback_right_block .feedback_ratig_right .br-theme-fontawesome-stars .br-widget a.br-active:after,
#feedback .feedback_right_block .feedback_ratig_right .br-theme-fontawesome-stars .br-widget a.br-selected:after {
    background: url('/img/svg/star_blue_full.svg') no-repeat;
}

#feedback .feedback_bottom .right .feedback_ratig_left_text {
    font-family: 'BentonSans-Bold', Arial;
    font-weight: 900;
}

#feedback .custom_recaptcha {
    margin-bottom: 20px;
    margin-top: 10px;
}

#feedback .feedback_bottom .row:last-child .left {
    padding-left: 15px;
    padding-top: 10px;
}

#feedback .feedback_bottom .row:last-child .right {
    padding-right: 15px;
}



.feedback #story h1 {
    padding-left: 15px;
}

.stoty_bottom_form .form-group.form_submit {
    float: right;
    width: 100%;
    margin-bottom: 0px;
}

/*End feedbeack page styles*/
/*story page styles*/
#story {
    font-size: 16px;
    /*margin-bottom: 30px;*/
}
#story.container-fluid {
    margin-top: 0;
}

#story h1, #story h3 {
    font-family: 'BentonSans-Bold', Arial;
    letter-spacing: 1.2px;
    font-weight: 900;
}

#story h1 {
    margin-left: -15px !important;
}

#story h1 {
    font-size: 20px;
    color: #858585;
    margin: 30px 0px;
}

#story h3 {
    font-size: 17px;
    color: #397fba;
    margin: 30px 0;
    padding-left: 15px;
}

.hotels_listing .col-xs-6 {
    margin-bottom: 30px;
}

#story .custom_pagination_inner div {
    margin-bottom: 30px;
}
#story .stoty_bottom_form .row {
    margin-bottom: 0
}

#story .item-description {
    margin-top: 20px;
    font-size: 16px;
    min-height: 110px;
    max-height: 110px;
    overflow: hidden;
}

.story #story .story_top {
    margin-bottom: 0px;
    padding-bottom: 15px
}

.story #story.hotels_inner_page .story_top {
    padding-bottom: 0px;
}

#story .story_top_text {
    letter-spacing: 2.1px;
    line-height: 30px;
}

#story .story_bottom_text .story_bottom_text_inner {
    letter-spacing: 2.1px;
    line-height: 34px;
    text-align: justify;
    margin-top: -10px;
}


#story.hotels_inner_page .story_bottom_text .story_bottom_text_inner {
    margin-top: 30px;
}

#story.hotels_inner_page h1 {
    padding-left: 15px
}

#story .story_page_socials ul {
    padding: 0;
    margin: 0;
}

#story .story_page_socials li {
    float: left;
    list-style: none;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    background: #858585;
    margin-right: 5px;
}

#story .story_page_socials i {
    color: #fff;
    font-size: 17px;
}

#story .story_page_socials .story_facebook:hover {
    background: #4267b2
}

#story .story_page_socials .story_twitter:hover {
    background: #1da1f2
}

#story .story_page_socials .story_google-plus:hover {
    background: #ea4235
}

#story .story_page_socials .story_pinterest:hover {
    background: #bd081c
}

#story .story_page_socials {
    padding-top: 0px;
    margin-bottom: 0px;
}

#story .stoty_bottom {
    width: 100%;
    float: left;
}

#story .stoty_bottom .recent_posts {
    width: 100%;
    float: left;
}

#story .recent_posts_title {
    color: #858585;
    font-family: 'BentonSans-Bold', Arial;
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: 900;
}

#story #similar_tours {
    padding: 30px 0px !important;
    padding-bottom: 0px !important;
    clear: both
}

#story #similar_tours .row {
    margin-bottom: 0px
}

.stoty_bottom_form {
    padding-bottom: 30px;
    clear: both
}

#story.story_inner_page .stoty_bottom_form form > .row {
    margin: 0px
}

/*End story page styles*/
/*All tours page styles*/

#all_tours {
    font-size: 16px;
}

#all_tours .container {
    clear: both
}

#all_tours h1, #all_tours h6 {
    font-family: 'BentonSans-Bold', Arial;
    letter-spacing: 1.2px;
    margin-top: 30px;
    font-weight: 900;
}

#all_tours h1 {
    font-size: 20px;
    color: #858585;
    margin-bottom: 30px;
}

#all_tours h6 {
    font-size: 17px;
    color: #397fba;
}

#all_tours .tour_menu li {
    margin-top: 0px;
    list-style: none;
    float: left;
    position: relative;
    padding-top: 5px;
}

.all_tours_top {
    padding-bottom: 30px;
}

.all_tours_top_text {
    font-size: 18px;
    padding-bottom: 15px;
    color: #565c64
}

.all_tours_row {
    margin-bottom: 30px;
}
.all_tours_row .col-xs-6 {
    margin-top: 30px;
}


.custom_pagination {
    text-align: center;
    margin: 0 auto;
    float: none;
    max-width: 380px;
    clear: both;
    display: block;
}

.custom_pagination .custom_pagination_inner {
    width: auto;
    border: none;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    float: none;
    border-radius: 0;
    margin: 0;
}
.custom_pagination .custom_pagination_inner:after {
    display: block;
    content: "";
    clear: both;
}
.custom_pagination .custom_pagination_inner:hover {
    background: transparent;
    border: none
}

.custom_pagination div a {
    color: #a1a1a1;
    display: block;
    line-height: 28px;
}

.custom_pagination div i {
    font-size: 20px;
}

.custom_pagination .fa-angle-right {
    padding-left: 1px
}

.custom_pagination_inner div {
    width: 30px;
    height: 30px;
    float: left;
    border-radius: 50%;
    border: 1px solid #a1a1a1;
    margin: 0 5px;
}

.custom_pagination div.active,
.custom_pagination div:hover {
    background: #2f7dc9;
    border: 1px solid #2f7dc9
}

.custom_pagination div.active a,
.custom_pagination div a:hover,
.custom_pagination div i:hover {
    color: #fff;
}

/*End Contact Us page styles*/
/*Evennts page styles*/

#events {
    margin-top: 30px;
    font-size: 16px;
    margin-bottom: 30px;
}

#events h1, #events h3 {
    font-family: 'BentonSans-Bold', Arial;
    letter-spacing: 1.2px;
    font-weight: 900;
}

#events h1 {
    font-size: 20px;
    color: #858585;
    margin: 30px 0;
}

#events h3 {
    font-size: 17px;
    color: #397fba;
}

#events .events_top_content ul {
    margin: 0;
    padding: 0
}

#events .events_top_content li {
    list-style: none;
    padding: 0 5px 5px 30px;
    position: relative
}

#events .events_top_content li:before {
    content: "";
    position: absolute;
    background-position: -126px -240px;
    left: 0;
    width: 25px;
    background-image: url(/img/sprite.png?v=1.2);
    height: 25px;
}

#events .events_top_text {
    padding: 25px 0;
    line-height: 30px;
}

#events .events_bottom_content .left {
    padding-left: 0px;
}

#events .events_bottom_content .right {
    padding-right: 0px;
}

#events .events_bottom_content .left img, #events .events_bottom_content .right img {
    width: 100%
}

#events .events_bottom_content h2, #events .bottom_container h2 {
    clear: both;
    padding: 30px 0px;
    font-family: 'BentonSans-Bold', Arial;
    font-size: 20px;
    color: #858585;
    margin: 0px;
    font-weight: 900;
}

#events #events_form .inner_left {
    padding: 0px;
}

#events #events_form label {
    font-weight: 300
}

#events #events_form #btnevents {
    width: 100px;
    float: right;
    background: #2f7dc9;
    letter-spacing: 3px;
    margin-right: 15px;
}

.client-logos-parent {
    background: #fff;
}

.client-logos-parent .client-logos {
    max-width: 1140px;
    margin: 0 auto;
    padding: 35px 0;
}

#events .slick-slide {
    margin: 0px 20px;
}

#events .slick-slide img {
    width: 100%;
}

#events .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

#events .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

#events .slick-list:focus {
    outline: none;
}

#events .slick-list.dragging {
    cursor: pointer;
}

#events.slick-slider .slick-track, #eventsv.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

#events .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}

#events.slick-track:before, #events .slick-track:after {
    display: table;
    content: '';
}

#events .slick-track:after {
    clear: both;
}

#events .slick-loading .slick-track {
    visibility: hidden;
}

#events .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

#events .slick-slide img {
    display: block;
}

#events .slick-slide.slick-loading img {
    display: none;
}

#events .slick-slide.dragging img {
    pointer-events: none;
}

#events .slick-initialized .slick-slide {
    display: block;
}

#events .slick-loading .slick-slide {
    visibility: hidden;
}

#events .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

/*.slick-arrow.slick-hidden {display: none;}*/

/*End Evennts  page styles*/
/*Booking form styles*/
.booking_modal_container .custom-modal-content {
    border-radius: 0px
}

.modal {
    z-index: 99999999
}

.booking_modal_container .custom-modal-content .modal-header {
    padding: 5px;
    background: #2f7dc9;
}

.booking_modal_container .custom-modal-content .modal-header .modal-title {
    text-align: center;
    color: #fff;
    line-height: 29px;
}

.booking_modal_container .modal-header .close {
    color: #fff;
    width: 30px;
    padding-bottom: 5px;
    margin-top: 2px
}

.booking_modal_container .modal-dialog {
    width: 540px;
    margin-left: auto;
    margin-right: auto;
}

.booking_modal_container .booking_form {
    margin: 20px 0
}

.booking_modal_container .booking_form .form-group label {
    width: 100%;
    display: block;
}

.modal_text {
    text-align: justify;
}

.booking_modal_container .booking_form .form-group, .modal_text {
    margin-bottom: 15px !important;
    width: 80%;
    margin: 0 auto;
    display: block;
    float: none;
}

.booking_modal_container .booking_form .form_submit {
    position: relative;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    clear: both;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.booking_modal_container .booking_form #btnBooking {
    width: 100%;
    background: #2f7dc9;
    position: relative;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    outline-offset: initial;
}

.modal_bottom ul {
    padding: 0px;
    margin: 0px;
}

.modal_bottom .line {
    position: absolute;
    width: 70%;
    height: 2px;
    background: #9dccfa;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
}

.modal_bottom li {
    list-style: none;
    float: left;
    width: 25%;
    text-align: center;
    font-size: 10px;
    position: relative;
}

.modal_bottom {
    min-height: 100px;
    position: relative
}

.modal_bottom .customize, .modal_bottom .confirmation {
    padding-top: 15px;
}

.modal_bottom li.active {
    font-family: 'BentonSans-Bold', Arial;
    font-weight: 900;
}

.modal_bottom li:last-child:after {
    display: none;
}

.modal_bottom li:after {
    content: "";
    position: absolute;
    height: 2px;
    background: #9dccfa;
    top: 40px;
    width: 100%;
    left: 50%;
}

.modal_bottom .information_step {
    width: 40px;
    height: 40px;
    margin: 5px auto;
    position: relative;
    z-index: 2;
    font-size: 14px;
    line-height: 22px;
    border: 2px solid #9dccfa;
    color: #9dccfa;
    text-align: center;
    position: relative;
    z-index: 2;
    background: #ffffff;
    border-radius: 50%;
    padding: 7px 0;
}
.modal_bottom .active .information_step {
    color: #ffffff;
    background: #9dccfa;
    font-family: 'BentonSans-Book', Arial;
    font-weight: normal;

}


/*.open_dropdown_menu.dropdown .dropdown_arrow:after {
    content: "\f106";
}*/



.custom_recaptcha {
    float: right;
    margin-top: 20px;
}

/*Booking form styles end*/

/*footer styles*/
footer {
    background: #252525;
}

.footer_top li {
    list-style: none;
    padding: 3px 0;
}

.footer_top ul {
    margin: 0;
    padding: 0;
}

.footer_top li a {
    font-family: BentonSans_book_regular, Arial;
    font-size: 14px;
    color: #fff;
    padding-left: 15px;
    position: relative;
}

body.lng-de .footer_top li a {
    font-family: 'BentonSans-Book', Arial;
}

.footer_top li a:before {
    content: "";
    width: 8px;
    height: 10px;
    background: url(/img/svg/right_slim.svg) no-repeat;
    background-size: 16px;
    background-position: -6px center;
    position: absolute;
    left: 0;
    top: 2px;
}

.footer_bottom {
    border-top: 1px solid #4b4b4c
}

.footer_top_inner {
    padding-bottom: 40px;
    padding-top: 40px;
    max-width: 1040px;
    margin: 0 auto;
    display: table;
    width: 100%
}

.footer_top_inner.top {
    padding-bottom: 0px
}

.footer_top_inner.bottom {
    padding-top: 0px;
}

.footer_top_inner img {
    padding-top: 27px;
}

.copyright {
    font-size: 15px;
    text-align: center;
    padding: 40px 0;
    color: #fff
}

/*** Suren added parts ****/
.form-group.form-error input, .form-group.form-error textarea {
    border-color: #f94359;
}

.error-message {
    color: #f94359;
    display: none;
    padding-top: 5px;
}

.form-error .error-message {
    display: block;
}

.current_tour_reviews img {
    max-width: 100%;
}

.tour_reviews_head {
    color: #337ab7;
    font-weight: 900;
    font-family: 'BentonSans-Bold', Arial;
    margin-bottom: 25px;
    font-weight: 900;
    line-height: 25px;
}

.show-more__out > div {
    width: 100%;
    max-height: 120px;
    margin-bottom: 52px;
}


/*.show-more__out .review-text {
    max-height: 120px;
    position: relative;
    overflow: hidden;
}*/
.show-more__out > div.full-story,
.show-more__out > div.full-story .review-text{
    max-height: initial;
    margin-bottom: 0;
}


a.toggle:after {
    content: " ";
    display: block;
    clear: both;
}

a.toggle {
    float: right;
}

.current_tour_reviews {
    margin: 30px 0;
    clear: both;
}


#tours_first_slider .right {
    padding-top: 0;
}


@media (max-width: 768px) {
    #similar_tours .col-xs-12 {
        margin-bottom: 30px;
    }
}
.reviews_block:after {
    content: '';
    display: block;
    clear: both;
}
.hotel-bg-section {
    padding: 0 15px 20px;
    background: #fff;
}
.hotels_listing .item-title a,
.news_items .item-title a{
    display: inline-block;
    vertical-align: top;
    color: inherit;
}
.reviews a {
    color: #fff;
}
#about_parent .about_our_mission h3 {
    margin-bottom: 0;
}
.bottom .reviews{
    float: right;
    font-size: 14px;
    padding-top: 2px;
}
.tour-block.all_tours_content {
    clear: both;
}
.custom_accordion_content h4.accardion_title {
    margin: 0;
}
#inclusions .custom_accordion_content h4.accardion_title {
    margin-bottom: 10px;
}
#inclusions .custom_accordion_content h4.accardion_title {
    margin-bottom: 10px;
}
.accardion_item.active #tour_reviews .custom_accordion_content {
    margin-top: 0px;
}
.custom_accordion_content .accordion_title{
    margin-bottom: 10px;
}

.usefull_information .travelers_items .bottom{
    height: 205px;
    padding-top: 0px;
    overflow:hidden;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    position:relative;
    background-color:#fff;
}

.usefull_information .travelers_items:hover .bottom {
    margin-top:-100px;
    height:304px;
    padding-top: 10px;
}
@media (max-width: 992px) {
    .usefull_information .travelers_items:hover .bottom {
        margin-top: 0 !important;
        height: auto !important;
        padding-top: 0 !important;
        max-height: 205px !Important;
    }

}

.adventur_sliders .owl-dots{display: none;}

#story .story_page_socials:before {
    content: '';
    clear: both;
    display: block;
}
.story_bottom_text:after {
    content: '';
    clear: both;
    display: block;
}
.hotel_title_block {
    padding-top: 135px;
    padding-bottom: 35px;
    background-size: cover !important;
    background-position: center !important;
    position: relative;
}
.hotel_title_block:before {
    position: absolute;
    display: block;
    content:"";
    left: 0;
    bottom: 0;
    width: 100%;
    height:130px;
    background: url('/img/shadow_bg.png') repeat-x;
    background-size: 1px auto;
    background-position: left bottom;
}
.hotel_name {
    color: #ffffff;
    margin: 0;
    font-size: 48px;
    line-height: 48px;
    font-family: 'BentonSans-Bold', Arial;
    position: relative;
    z-index: 2;
    font-weight: 900;
}
.hotel_images {
    max-width: 860px;
    padding-top: 30px;
    margin: 0 auto;
}
.big_images,
.small_images,
.bike_images{
    position: relative;
    font-size: 0;
    overflow: hidden;
    white-space: nowrap;
}
.small_images,
.big_images{
    margin-bottom: 27px;
}
.big_images .image_block,
.small_images .image_block,
.bike_images .slide_block {
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
}
.small_images .image_block {
    padding: 0 2px;
    width: 10%;
    cursor: pointer;
}
.small_images .image_block.slick-current {
    cursor: default;
}
.small_images .image_block .image_inner {
    position: relative;
    overflow: hidden;
}
.small_images .image_block .image_inner img {
    width: 133%;
    margin-left: -16.5%;
}
.big_images .image_block,
.big_images .image_block img,
.bike_images .slide_block,
.bike_images .slide_block img {
    width: 100%;
}
.big_images .slick-arrow,
.bike_images .slick-arrow{
    position: absolute;
    border: none;
    top: 50%;
    margin-top: -50px;
    width: 50px;
    height: 100px;
    z-index: 2;
    padding: 0;
    background: url(/img/svg/right_exslim.svg) no-repeat;
    background-size: 100px;
    background-position: center;
    opacity: 0.67;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    outline: none !important;
}
.big_images .slick-prev,
.bike_images .slick-prev{
    left: 107px;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.big_images .slick-next,
.bike_images .slick-next{
    right: 107px;
}
.big_images .slick-arrow:hover,
.bike_images .slick-arrow:hover{
    opacity: 1;
}
.hotel_info_block {
    padding-bottom: 30px;
}
.hotel_info_text {
    font-size: 16px;
    line-height: 30px;
    color: #565c64;
    padding-bottom: 20px;
    margin: 0;
}
.details_subtitle {
    display: block;
    font-size: 28px;
    line-height: 36px;
    padding: 3px 0 3px 22px;
    border-left: 2px solid #2f7dc9;
    color: #2f7dc9 !important;
    margin-bottom: 25px;
    margin-top: 25px;
}
.details_list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 15px;
    line-height: 26px;
    color: #2f7dc9;
}
.details_list p {
    margin: 0;
    font-size: 16px;
    line-height: 30px;
}
.details_list ul + .details_subtitle,
hotel_info_text + .details_subtitle,
.details_subtitle:first-child{
    margin-top: 0;
}

.details_list ul li {
    padding-bottom: 22px;
    position: relative;
    padding-left: 40px;
}
.details_list ul li:before {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    top: 8px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #7d7d7d;
}
.details_list ul.two_col_list {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 50px;
    -webkit-column-gap: 50px;
    column-gap: 50px;
}
.details_list ul.two_col_list li {
    display: inline-block;
    vertical-align: top;
    width: 100%;
}
.landing_title_block {
    background-size: cover !important;
    background-position: center !important;
    padding-top: 135px;
    padding-bottom: 35px;
    position: relative;
}
.landing_title {
    color: #ffffff;
    margin: 0;
    font-size: 36px;
    line-height: 36px;
    font-family: 'BentonSans-Bold', Arial;
    position: relative;
    z-index: 2;
    font-weight: 900;
}
.landing_title_block:before {
    position: absolute;
    display: block;
    content:"";
    left: 0;
    bottom: 0;
    width: 100%;
    height:130px;
    background: url('/img/shadow_bg.png') repeat-x;
    background-size: 1px auto;
    background-position: left bottom;
}
.landing_prelude {
    margin-top: 20px;
    font-size: 15px;
    line-height: 30px;
    position: relative;
    color: #ffffff;
    overflow: hidden;
    z-index: 2;
}
.prelude_inner * {
    margin: 0 !important;
}
.more_less {
    display: none;
    color: #ffffff !important;
    position: relative;
    z-index: 2;
}
.landing_epilogue {
    padding: 30px 0;
}
.container-fluid .landing_epiloge,
.standard_info_page .landing_epiloge{
    padding-bottom: 0;
}
.epilogue_decor {
    display: block;
    height: 68px;
    background-size: cover !important;
    background-position: center !important;
    margin-bottom: 30px;
}
.epilogue_inner {
    font-size: 15px;
    line-height: 24px;
    position: relative;
    color: #858584;
    overflow: hidden;
}
.epilogue_inner p {
    margin: 0;
}
.more_less2 {
    color: #397fba !important;
}
textarea {
    resize: none;
}
#modal-prepayment {
    padding: 30px 15px !important;
}
.payment_popup {
    width: 100%;
    font-family: 'BentonSans-Book', Arial;
    display: table;
    height: 100%;

}
.payment_inner {
    display: table-cell;
    vertical-align: middle;
}
.first_step_block {
    border-radius: 9px;
    background: #ffffff;
    max-width: 375px;
    margin: 0 auto;
    padding: 27px;
    position: relative;
}
.popup_logo_block {
    text-align: center;
    padding-bottom: 27px;
}
.popup_logo_block img {
    vertical-align: top;
    height: 46px;
    width: auto;
}
.steps_list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: relative;
}
.steps_list:before {
    display: block;
    position: absolute;
    content: "";
    left: 5px;
    right: 5px;
    height: 2px;
    top: 36px;
    background: #9dccfa;
}
.steps_list li {
    float: left;
    width: 33.33%;

}
.step_info_block {
    display: inline-block;
    vertical-align: top;
}
.step_name_block {
    font-size: 10px;
    line-height: 10px;
    color: #454545;
    white-space: nowrap;
}
.step_number {
    margin-top: 16px;
    margin-bottom: 6px;
    width: 40px;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    line-height: 22px;
    border: 2px solid #9dccfa;
    color: #9dccfa;
    text-align: center;
    position: relative;
    z-index: 2;
    background: #ffffff;
    border-radius: 50%;
    padding: 7px 0;
}
.step_name_block + .step_number {
    margin-top: 6px;
    margin-bottom: 16px;
}
.steps_list li.active .step_number {
    color: #ffffff;
    background: #9dccfa;
}
.steps_list li.active .step_name_block {
    font-family: 'BentonSans-Bold', Arial;
    color: #858585;
    font-weight: 900;
}
.about_payment {
    margin: 0;
    padding: 15px 0;
    list-style-type: none;
}
.about_payment li {
    position: relative;
    line-height: 28px;
    font-size: 17px;
    color: #858585;
    padding-left: 40px;
 }
.about_payment li:before {
    display: block;
    position: absolute;
    content: "";
    left: 14px;
    top: 7px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
     background: #9dccfa;
 }
.field_block {
    border: 1px solid #858686;
    border-radius: 9px;
    padding: 0 39px;
    font-size: 17px;
}
.payment_currency,
.payment-amount {
    display: inline-block;
    vertical-align: top;
    background: transparent !important;
    max-width: 180px;
    border: none;
    padding: 0;
    line-height: 30px;
    color: #858585;
    outline: none !important;
}
#amd-place {
    position: relative;
}
.popup_close {
    position: absolute;
    border: none;
    z-index: 2;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: url('/img/svg/cancel.svg') no-repeat;
    background-size: 38px;
    top: -32px;
    right: -32px;
}
.payment_success {
    width: 100%;
    max-width: 630px;
    border-radius: 9px;
    height: 195px;
    background-color: #ffffff;
    background-image: url("/img/popup_decor.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: left top;
    padding: 20px 35px 20px 0;
    position: relative;
    margin: 0 auto;
    display: none;
}
.payment_success.showed {
    display: table;
}
.success_info {
    font-family: 'BentonSans-Book', 'Arial';
    padding: 15px 90px 15px 100px;
    font-size: 24px;
    line-height: 30px;
    color: #858585;
    background: url('/img/mounth_red.png') no-repeat;
    background-size: auto 25px;
    background-position: right bottom;
}

.field_block.disabled{
    background: #ebebe4;
}
.mt30{
    margin-top: 30px;
}
.mb30{
    margin-bottom: 30px;
}
.country-am .sale_price,
.country-am .tour_details .tour_price_block,
.country-am .tour_dates_mobile_price h4,
.country-am .price_block .price,
.country-am .price_block .sale_price,
.country-am .travelers_items .price_block,
.country-am .guaranteed-price h4,
.country-am .supplement_price,

.country-az .sale_price,
.country-az .tour_details .tour_price_block,
.country-az .tour_dates_mobile_price h4,
.country-az .price_block .price,
.country-az .price_block .sale_price,
.country-az .travelers_items .price_block,
.country-az .guaranteed-price h4,
.country-az .supplement_price,

.country-ge .sale_price,
.country-ge .tour_details .tour_price_block,
.country-ge .tour_dates_mobile_price h4,
.country-ge .price_block .price,
.country-ge .price_block .sale_price,
.country-ge .travelers_items .price_block,
.country-ge .guaranteed-price h4,
.country-ge .supplement_price
{
    display: none;
}

#about_parent h4 {
    color: #858585;
    font-family: BentonSans-Bold,Arial;
    margin-bottom: 30px;
    margin-top: 0;
    font-weight: 900;
}
.payment-success {
    width: 320px;
    margin: 20px auto;
}
.make_img_resp img
{
    max-width: 100%;
}
.news_recaptcha>div {
    margin-top: 0;
    margin-bottom: 10px;
}
.news_recaptcha.custom_recaptcha{
    margin-top: 0;
}
.service_append {
    margin: 0 30px;
}
.append_inner {
    display: none;

}
.append_inner.already_opened {
    display: block;
}
.about_book_text {
    border: 1px solid #d2d2d2;
    background: #ffffff;
    border-radius: 7px;
    z-index: 2;
    font-size: 12px;
    color: #4c4c4c;
    line-height: 14px;
    padding: 10px 25px;
    position: relative;
    margin-top: 10px;
}
.about_book_text:before {
    display: block;
    position: absolute;
    content: "";
    width: 21px;
    height: 11px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #d2d2d2 transparent;
    top: -11px;
    margin-left: -10px;
}
.about_book_text:after {
    display: block;
    position: absolute;
    content: "";
    width: 21px;
    height: 11px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    top: -10px;
    margin-left: -10px;
    z-index: 2;
}
.pos1 .about_book_text:before,
.pos1  .about_book_text:after{
    left: 16.66%;
}
.pos2 .about_book_text:before,
.pos2 .about_book_text:after{
    left: 50%;
}
.pos0 .about_book_text:before,
.pos0 .about_book_text:after{
    left: 83.33%;
}
.book_with_Arara .col-lg-4 div.clickable:hover {
    cursor: pointer;
    opacity: 0.8;
}

.rating_block {
    width: 125px;
    height: 25px;
    position: relative;
    float: left;
}
.current_tour_reviews .rating_block {
    float: right;
}
.rating_block span {
    display: block;
    height: 100%;
    background-size: 25px !important;
    background-repeat: repeat-x !important;
    background-position: left top !important;
}
.rating_block span.full_stars {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background: url('/img/svg/star_blue_full.svg');
}
.empty_stars {
     background: url('/img/svg/star_blue_empty.svg');
 }
.banner_review .empty_stars {
    background: url('/img/svg/star_white_empty.svg');
}
.banner_review .rating_block span.full_stars {
    background: url('/img/svg/star_white_full.svg');
}
.tour_reviews_text p {
    margin-bottom: 0;
    margin-top: 22px;
}
.tour_reviews_text p:first-child {
    margin-top: 0;
}
.tour_reviews_text {
    max-height: 88px;
    position: relative;
    overflow: hidden;
}
.current_tour_reviews.opened .tour_reviews_text {
    max-height: 100%;
}
.review_drop {
    display: none;
    font-size: 16px;
    line-height: 24px;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
    color: #858585;
    margin-top: 5px;
}
.review_drop .close_text,
.current_tour_reviews.opened .review_drop .open_text {
    display: none;
}
.review_drop .open_text,
.current_tour_reviews.opened .review_drop .close_text {
    display: block;
    float: left;

}
.review_drop:after {
    display: block;
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-left: 10px;
    background: url('/img/svg/down_white.svg') #858585 no-repeat;
    background-size: 7px auto;
    background-position: center;
    margin-top: 4px;
    float: left;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -ms-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
.current_tour_reviews.opened .review_drop:after {
    -webkit-transform: scaleY(-1);
    -moz-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    transform: scaleY(-1);
}
.review_drop:hover {
    color: #333333;
}
.review_drop:hover:after {
    background-color: #333333;
}
.review_images {
    margin:0 -22px;
    padding: 0;
    list-style-type: none;
    font-size: 0;
}
.review_images li {
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    padding: 22px;
    margin-left: -1px;
}
.review_images img {
    display: block;
    width: 100% !important;
    height: auto !important;
}
.standard_info_page {
    padding: 30px 0;
}
.event_main_image {
    max-width: 850px;
    margin: 0 auto;
}
.event_main_image img {
    width: 100% !important;
    height: auto !important;
}
.page_text_block {
    font-size: 18px;
    line-height: 30px;
    color: #858585;
    margin-top: 25px;
}
.standard_info_page .two_cols_list {
    margin: 20px 0 0;
    padding: 0;
    list-style-type: none;
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 50px;
    -webkit-column-gap: 50px;
    column-gap: 50px;

}
.standard_info_page .two_cols_list li {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}
.check_type_list li,
.arrow_type_list li,
.round_arrow_list li {
    padding-left: 30px;
    padding-bottom: 20px;
    position: relative;
    font-size: 18px;
    line-height: 28px;
    color: #858585;
}
.check_type_list li:before,
.arrow_type_list li:before,
.round_arrow_list li:before{
    display: block;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 20px;
    height: 28px;
}
.check_type_list li:before {
     background: url('/img/svg/checked_blue.svg') no-repeat;
     background-size: 18px auto;
     background-position: left center;
 }
.arrow_type_list li:before {
    background: url('/img/svg/arrow_right_blue.svg') no-repeat;
    background-size: 16px auto;
    background-position: left center;
}
.round_arrow_list li:before {
    background: url('/img/round_arrow.png') no-repeat;
    background-size: 18px auto;
    background-position: left center;
}
.standard_info_page .two_cols_list + * {
    margin-top: 5px;
}
.download_link {
    display: inline-block;
    vertical-align: top;
    color: #ffffff !important;
    padding: 5px 20px;
    background: #2f7dc9;
    margin-top: 25px;
    font-size: 18px;
    line-height: 30px;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}
.download_link:hover,
.contacts_section .form_button:hover {
    opacity: 0.7;
}
.clients_section,
.contacts_section{
    padding-top: 25px;
}
.clients_slider {
    background: #ffffff;
    padding: 70px 0;
    position: relative;
    overflow: hidden;
}
.clients_slider .slider_line {
    position: relative;
    overflow: hidden;
    font-size: 0;
    white-space: nowrap;
    margin: 0 -20px;
    text-align: center;
}
.clients_slider .slider_line.slick-slider {
    overflow: visible;
}
.clients_slider .slider_line .slide_block {
    display: inline-block;
    vertical-align: middle;
    float: none !important;
    width: 14.285%;
    padding: 0 20px;
}
.clients_slider .slider_line:before,
.clients_slider .slider_line:after {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    width: 20px;
    z-index: 5;
    background: #ffffff;
    height: 100%;
}
.clients_slider .slider_line:before {
    left: 0;
}
.clients_slider .slider_line:after {
    right: 0;
}
.clients_slider .slider_line .slide_block a {
    display: inline-block;
    vertical-align: top;
    filter:grayscale(100%);
    -webkit-filter:grayscale(100%);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.clients_slider .slider_line .slide_block a:hover {
    filter:grayscale(0%);
    -webkit-filter:grayscale(0%);
}
.clients_slider .slider_line .slide_block img {
    max-width: 100%;
    max-height: 100px;
}
.clients_slider .slick-arrow {
    position: absolute;
    border: none;
    top: 50%;
    margin-top: -20px;
    width: 23px;
    height: 40px;
    z-index: 2;
    padding: 0;
    background: url(/img/svg/right_exslim_black.svg) no-repeat;
    background-size: 40px;
    background-position: center;
    opacity: 0.67;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    outline: none !important;
    z-index: 6;
}
.clients_slider .slick-arrow:hover {
    opacity: 1;
}
.clients_slider .slick-prev {
    left: -33px;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.clients_slider .slick-next {
    right: -33px;
}
.contacts_section form {
    padding-top: 20px;
}
.contacts_section .form_block {
    position:relative;
    padding-bottom: 20px;
}
.contacts_section .field_name {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 5px;
    color: #858585;
    font-family: 'BentonSans-Bold', Arial;
    font-weight: 900;
}
.contacts_section .form_block input,
.contacts_section .form_block textarea {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: #858585;
    padding: 6px 15px;
    border: 1px solid #414141;
    border-radius: 8px;
    background: transparent;
    outline: none;
}
.contacts_section .form_block textarea {
    resize: none;
    height: 196px;
}
.contacts_section .form_button {
    margin-top: 5px;
}
.guide_image_block {
    padding-right: 20px;
    width: 470px;
    float: left;
}
.guide_image_block img {
    display: block;
    max-width: 100% !important;
    height: auto !important;
}
.guide_info_block {
    padding-top: 30px;
    padding-left: 470px;
}
.guide_info_block .details_subtitle {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
}
.epilogue_title {
    font-size: 35px;
    line-height: 48px;
    padding: 10px 15px;
    display: block;
    color: #ffffff;
    font-family: 'BentonSans-Bold', Arial;
    font-weight: 900;
}
.landing_epilogue .current_tour_reviews:first-child {
    margin-top: 10px;
}
.landing_epilogue .load_more_reviews {
    margin-bottom: 0;
}
.bold_text {
    font-family: 'BentonSans-Bold', Arial;
    font-weight: 900;
}
.bike_characteristics {
    position: relative;
    margin-top: 25px;
}

.standard_info_page .hotel_images {
    padding-top: 0;
}
.bike_characteristics > div {
    float: left;
    width: 33.33%;
    position: relative;
    z-index: 3;
}
.character_type {
    margin-bottom: 20px;
}
.character_icon {
    display: inline-block;
    border-right: 2px solid #2f7dc9;
    vertical-align: middle;
    padding-right: 12px;
}
.technical_details .character_icon img {
    display: block;
    width: auto;
    height: 52px;
    margin: 4px 0;
}
.requirement_details .character_icon img {
    display: block;
    width: auto;
    height: 50px;
    margin: 5px 0;
}
.price_details .character_icon img {
    display: block;
    width: auto;
    height: 56px;
    margin: 2px 0;
}
.character_name {
    display: inline-block;
    font-size: 18px;
    vertical-align: middle;
    color: #2f7dc9;
    line-height: 30px;
    padding-left: 12px;
    max-width: 60%;
}
.bike_characteristics ul {
    margin: 0;
    padding: 0 30px 0 0;
    list-style-type: none;
}
.bike_characteristics ul li:last-child {
    padding-bottom: 0;
}
.application_form .form_block textarea {
    height: 450px;
}
.about_who_are {
    padding-top: 5px;
}
.about_who_are h3,
.about_offer h3,
.about_our_mission h3,
.about_our_guides_parent h3,
.about_our_team h3,
.legal_information h3 {
    display: block;
    font-size: 28px;
    line-height: 36px;
    padding: 3px 0 3px 22px;
    border-left: 2px solid #2f7dc9;
    color: #2f7dc9 !important;
    margin: 25px 0;
    font-weight: normal;
}
.about_who_are_content,
.about_our_mission,
.about_our_guides,
.about_our_team,
.about_offer_content{
    font-size: 18px;
    color: #858585;
    line-height: 30px;
}
 .guide_reviews a, .legal_information div {
    line-height: 30px;
     font-size: 18px;
}
#about_parent .about_offer h3 {
    padding-bottom: 30px;
}
.about_offer_content  {
    position: relative;
    padding-left: 40px;
    padding-bottom: 22px;
}
.about_offer_content:last-child {
    padding-bottom: 0;
}
.about_offer_content:before {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    top: 8px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #7d7d7d;
}
.about_our_team {
    position: relative;
    overflow: hidden;
}
.contacts_links {
    margin: 0 -20px;
    padding: 30px 0;
    list-style-type: none;
}
.contacts_links li {
    padding: 20px;
    float: left;
}
.contacts_links a {
    display: block;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    font-size: 0;
    white-space: nowrap;
}
.contacts_links a:hover {
    opacity: 0.7;
}

.contact_icon {
    margin-right: 20px;

    display: inline-block;
    vertical-align: top;
}
.tripadvisor_icon img,
.google_icon img{
    width: 44px;
    margin-top: 8px;
}
.globe_icon img {
    width: 30px;
    margin-top: 15px;
}
.facebook_icon {
    width: 20px;
    position: relative;
    overflow: hidden;
}
.facebook_icon img {
    width: 32px;
    margin-top: 14px;
    margin-left: -6px;
}
.contact_name {
    display: inline-block;
    vertical-align: top;
    padding: 20px 0 20px 10px;
    color: #2f7dc9;
    font-size: 14px;
    line-height: 20px;
    border-left: 2px solid #2f7dc9;
}
.member_name {
    display: block;
    padding: 0 25px 10px;
    font-size: 14px;
    line-height: 20px;
    color: #5e5e5e;
    height: 35px;
    text-align: center;
}
.our_guides .guides a {
    position: relative;
    display: block;
}
.guide_name {
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 125px;
    max-width: 100%;
    color: #ffffff;
    font-family: 'BentonSans-Bold', Arial;
    font-size: 18px;
    text-align: left;
    padding: 10px 25px;
    font-weight: 900;
    background-color: #7d7d7d;
    z-index: 1;
}
.our_guides .guides ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.bold_style {
    font-family: 'BentonSans-Bold', Arial;
    font-weight: 900;
}
.blue_color {
    color: #2f7dc9;
}
.members_social {
    margin-top: 10px;
}
.itinerary_footer {
    font-size: 0;
    text-align: center;
    padding-top: 25px;
    margin: 0 -15px;
}
.it_footer_block {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 15px 15px 45px;
    border-left: 1px solid #cccccc;
    font-size: 18px;
    line-height: 22px;
}
.it_footer_block:first-child {
    border: none;
}
.it_footer_block:first-child:last-child {
    padding-bottom: 0;
}
.it_footer_title {
    max-width: 384px;
    margin: 0 auto;
    position: relative;
    padding-left: 40px;
    padding-bottom: 10px;
    font-weight: 900;
    font-family: 'BentonSans-Bold', Arial;
    color: #807f7f;
    text-align: left;
}
.it_footer_title:before,
.it_footer_title:after {
    display: block;
    position: absolute;
    content: "";
    left: 0;
    top: 6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #2f7dc9;
}
.it_footer_title:after {
    margin-left: 15px;
}
.it_footer_button {
    display: block;
    max-width: 384px;
    margin: 0 auto;
    color: #2f7dc9;
    border: 1px solid #2f7dc9;
    padding: 5px 15px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.it_footer_button:hover {
    color: #ffffff;
    background: #2f7dc9;
}
.hotels_listing .item-img {
    position: relative;
}
.hotel_stars {
    display: block;
    font-size:0;
    text-align: right;
}

.hotel_stars .star_block {
    display: inline-block;
    vertical-align: top;
    margin-left: 1px;
    background: url('/img/svg/star_hotel.svg') no-repeat;
    background-size: 100%;
}
.hotels_listing .hotel_stars,
.tour_hottels .hotel_stars  {
    position: absolute;
    right: 15px;
    bottom: 15px;
    z-index: 1;
}
.hotels_listing .star_block,
.tour_hottels .star_block {
    width: 24px;
    height: 24px;
}
.landing_title .hotel_stars {
    float: right;
    margin-left: 30px;
    padding-top: 3px;
}
.landing_title .star_block {
    width: 31px;
    height: 31px;
}
.own_itinerary {
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    position: relative;
    z-index: 2;
}
.own_itinerary .table_block {
    height: 68px;
}
own_itinerary_info {
    font-weight: 900;
    font-family: 'BentonSans-Bold', Arial;
    min-width: 50%;
}
.own_itinerary_button {
    padding-left: 30px;
    white-space: nowrap;
    text-align: right;
}
.own_itinerary_button a {
    display: inline-block;
    vertical-align: top;
    width: 375px;
    max-width: 100%;
    text-align: center;
    border: 1px solid;
    color: inherit;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    padding: 3px 15px;
}
.own_itinerary_button a:hover {
    opacity: 0.7;
}
tour_second_accordions + * {
    margin-top: 0 !important;
    padding-top: 10px !important;
}
.mb30 {
    margin-bottom: 30px;
}
#about_parent p:last-child {
    margin-bottom: 0;
}
.pagination {
    margin: 0;
    display: block;
}
.pagination > * {
    margin: 20px 0;
}
.messenger_block {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    right: 60px;
    -webkit-transition: z-index 0.5s;
    -moz-transition: z-index 0.5s;
    -ms-transition: z-index 0.5s;
    -o-transition: z-index 0.5s;
    transition: z-index 0.5s;
}
.menu_opened .messenger_block {
    z-index: 500;
}
.messenger_open {
    display: block;
    min-width: 65px;
    color: #ffffff !important;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    padding: 0 10px;
    position: relative;
    background: #848585;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}
.messenger_open:before {
    position: absolute;
    display: block;
    content: "";
    font-weight: 900;
    font-family: 'BentonSans-Bold', Arial;
    top: 0;
    height: 100%;
    background: url('/img/svg/chat.svg') no-repeat #2f7dc9;
    background-size: 15px auto;
    background-position: center;
    width: 35px;
    left: -37px;
}
.messenger_open:after {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    height: 100%;
    width: 2px;
    left: -2px;
}
.messenger_inner {
    position: absolute;
    right: 0;
    width: 235px;
    display: none;
    bottom: 0;
    z-index: 5;
    overflow: hidden;
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    transition: height 0.5s;
    box-shadow: 0 3px 10px rgba(0,0,0,0.06);
}
.messenger_form {
    position: relative;
    background: #ffffff;
    overflow-x: hidden;
    overflow-y: auto;

}
.messenger_head {
    background: #848585;
    color: #ffffff;
    padding: 3px 30px 3px 15px;
    font-size: 14px;
    line-height: 20px;
}
.messenger_close {
    display: block;
    position: absolute;
    right: 5px;
    top: 3px;
    width: 20px;
    height: 20px;
    background: url('/img/svg/close_white.svg') no-repeat;
    background-size: 10px auto;
    background-position: center;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}
.messenger_form .form_inner {
    padding: 5px 10px 10px;
    font-size: 14px;
    line-height: 20px;
    color: #858c95;
}
.about_messenger {
    padding-bottom: 10px;
}
.messenger_form .field_name {
    padding-bottom: 5px;
    font-weight: 900;
    font-family: 'BentonSans-Bold', Arial;
}
.messenger_form .form_field {
    padding-bottom: 10px;
    margin-bottom: 0;
}
.messenger_form input,
.messenger_form textarea {
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
    color: inherit;
    border: 1px solid #848585;
    border-radius: 4px;
}
.messenger_form textarea {
    height: 66px;
    outline: none;
}
.article_feedback_form .form_submit button,
.stoty_bottom_form .form-group.form_submit button,
#contact_us .contact_us_form .form_submit button,
.payment_button,
.contacts_section .form_button,
.messenger_form button,
#btnfeedback{
    border: none;
    width: 100%;
    color: #ffffff;
    background: #2f7dc9;
    font-size: inherit;
    line-height: 20px;
    padding: 7px;
    border-radius: 0;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}
.article_feedback_form textarea.form-control,
.feedback_bottom textarea.form-control,
#contact_us .contact_us_form textarea.form-control{
    height: 196px;
}
#contact_us .contact_us_form label {
    line-height: 18px;
}
.article_feedback_form .form_submit button:hover,
.stoty_bottom_form .form-group.form_submit button:hover,
#contact_us .contact_us_form .form_submit button:hover,
#btnfeedback:hover,
.messenger_close:hover,
.messenger_open:hover,
.messenger_form button:hover,
.payment_button:hover{
    opacity: 0.7;
}
.stoty_bottom_form .form-group.form_submit button,
.payment_button {
    margin-top: 5px;
}
.article_feedback_form .form-group {
    margin-bottom: 20px;
}
.own_tour_head {
    height: 400px;
    background-size: cover !important;
    background-position: center !important;
    color: #ffffff;
    font-weight: 900;
    font-family: 'BentonSans-Bold', Arial;
    text-align: center;
    font-size: 75px;
    line-height: 90px;
}
.own_tour_form {
    margin-top: -95px;
    padding-bottom: 30px;
}
.own_tour_form form {
    padding: 90px 40px 30px;
    background: #ffffff;
 }
.own_tour_form form:before {
    display: block;
    content: "";
    height: 1px;
    background: #cccccc;
    margin-bottom: 45px;
}
.own_tour_form .form_field {
    position: relative;
    padding-bottom: 35px;
}
.own_tour_form .field_name {
    font-size: 26px;
    line-height: 31px;
    margin-bottom: 30px;
    color: #858585;
    font-weight: 900;
    font-family: 'BentonSans-Bold', Arial;
}
.own_tour_form .field_hint {
    font-size: 16px;
    line-height: 19px;
    color: #858585;
    max-width: 800px;
}
.own_tour_form .field_name + .field_hint {
    margin-top: -10px;
    margin-bottom: 20px;
}
.own_tour_form .checkbox_group {
    margin: -10px -15px 0;
    font-size: 0;
}
.own_tour_form .checkbox_label {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
    padding: 10px 15px 0;

}
.own_tour_form .checkbox_label label {
    font-weight: normal;
    position: relative;
    vertical-align: top;
    margin: 0;
}
.own_tour_form .checkbox_label input {
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
}
.own_tour_form .checkbox_button {
    display: block;
    white-space: nowrap;
    font-size: 20px;
    line-height: 30px;
    color: #858585;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}
.own_tour_form .checkbox_button:before {
    display: inline-block;
    vertical-align: top;
    content: "";
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border: 1px solid #bfbfbf;
    -webkit-transition: border-color 0.3s;
    -moz-transition: border-color 0.3s;
    -ms-transition: border-color 0.3s;
    -o-transition: border-color 0.3s;
    transition: border-color 0.3s;
}
.own_tour_form .checkbox_button:hover {
    color: #000000;
}
.own_tour_form .checkbox_button:hover:before {
    border-color: #000000;
}
.own_tour_form .checkbox_button.checked:before {
    background: url('/img/svg/checked_blue2.svg') no-repeat;
    background-size: 19px auto;
    background-position: center;
}
.own_tour_form .count_field {
    display: inline-block;
    vertical-align: top;
    min-width: 250px;
    margin-right: 30px;
}
.own_tour_form .count_field .field_hint {
    min-width: 65px;
    padding: 15px 25px 15px 0;
}
.own_tour_form .count_block {
    width: 185px;
    border: 2px solid #848585;
    border-radius: 4px;
    padding: 12px 17px 12px 20px;
}
.own_tour_form .count_block a {
    display: block;
    width: 22px;
    height: 22px;
    margin-left: 25px;
    background-size: 100% !important;
    opacity: 0.48;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}
.own_tour_form .count_block a:hover {
    opacity: 1;
}
.increase {
    background: url('/img/svg/increase.svg') no-repeat;
}
.decrease {
    background: url('/img/svg/decrease.svg') no-repeat;
}
.own_tour_form .count_block input {
    font-size: 18px;
    line-height: 22px;
    padding: 0;
    width: 40px;
    color: #858585;
    font-weight: 900;
    font-family: 'BentonSans-Bold', Arial;
    border: none;
    background: transparent;
    outline: none;
}
.own_tour_form .form_field > input,
.own_tour_form textarea {
    font-size: 16px;
    line-height: 22px;
    border: 2px solid #848585;
    border-radius: 4px;
    padding: 12px 15px;
    color: #858585;
    width: 100%;
}
.own_tour_form textarea {
    height: 170px;
}
.own_tour_form .form_field.fl {
    width: 50%;
    padding-right: 15px;
    max-width: 460px;
    padding-bottom: 15px;
}
.own_tour_form .form_field.fr {
    width: 50%;
    padding-left: 15px;
    max-width: 460px;
    padding-bottom: 15px;
}
.own_tour_form .form_field.fl .field_name,
.own_tour_form .form_field.fr .field_name {
    margin-bottom: 10px;
}
.own_tour_form .form_button {
    padding-top: 15px;
}
.own_tour_form button {
    float: right;
    min-width: 175px;
    color: #ffffff;
    background: #2f7dc9;
    border: none;
    line-height: 30px;
    padding: 10px 15px;
}

.own_tour_form button {
    font-size: 24px;
}
.supplement_type {
    display: inline-block;
    vertical-align: top;
    width: 190px;
}
.supplement_price {
    display: inline-block;
    vertical-align: top;
    color: #2f7dc9;
    font-weight: 900;
    font-family: 'BentonSans-Bold', Arial;
    margin-left: 13px;
    padding-left: 13px;
    border-left: 1px solid #b0b0b0;
}
.review_date {
    font-size: 16px;
    line-height: 24px;
    padding-left: 24px;
    color: #858585;
    position: relative;
    margin-top: 5px;
}
.review_date:before {
    display: block;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 16px;
    height: 24px;
    background: url('/img/svg/clock.svg') no-repeat;
    background-position: left center;
    background-size: 16px;
    opacity: 0.45;
}
.adv_slider {
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    font-size: 0;
}
.adv_slider.slick-slider {
    overflow: visible;
}
.adv_slider .travelers_items {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    white-space: normal;
}
.adv_slider .slick-arrow {
    position: absolute;
    border: none;
    right: 0;
    top: -30px;
    background: url('/img/svg/right_slim.svg') no-repeat;
    background-size: 16px;
    background-position: center;
    z-index: 3;
    width: 30px;
    height: 30px;
}
.adv_slider .slick-prev {
    margin-right: 30px;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.custom_pagination a i {
    display: block;
    width: 100%;
    height: 28px;
    font-size: 0;
    opacity: 0.48;
    background: url('/img/svg/right_black.svg') no-repeat;
    background-size: 14px;
    background-position: center;
}
.custom_pagination a i.fa-angle-left {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}
.custom_pagination a:hover i {
    background: url('/img/svg/right_slim.svg') no-repeat;
    background-size: 14px;
    background-position: center;
    opacity: 1;
}
.br-current-rating {
    display: none !important;
}
div#design-your-tour-success {
    margin-top: 120px;
    color: #858585;
    text-align: center;
    font-size: 2em;
}
.black_subtitle {
    font-size: 16px;
    line-height: 22px;
    font-weight: 900;
    font-family: 'BentonSans-Bold', Arial;
    color: #858585;
    margin-top: 15px;
}
.black_subtitle + .page_text_block {
    margin-top: 10px;
}
@media screen and (min-width: 1440px) {
    .tour_title_block .tour_title {
        font-size: 75px;
        line-height: 85px;
    }
}
.success-message-place {
    padding: 30px 0;
    min-height: 100px;
}
.guide-inner .landing_epilogue {
    padding-bottom: 0;
}

.slick-track,
.slick-list {
    -webkit-perspective: 2000;
    -webkit-backface-visibility: hidden;
}
.article_feedback_form .form_title {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #2f7dc9;
    font-weight: 900;
    font-family: 'BentonSans-Bold', Arial;
    margin-bottom: 30px;
}
.article_feedbacks {
    font-size: 16px;
    line-height: 24px;
    color: #858585;
    padding-bottom: 20px;
}
.article_feedbacks .feedbacks_count {
    font-weight: 900;
    font-family: 'BentonSans-Bold', Arial;
    margin-top: 10px;
}
.article_feedbacks .feedback_block {
    margin-top: 10px;
}
.article_feedbacks .feedback_author {
    letter-spacing: 1px;
    color: #2f7dc9;
    font-weight: 900;
    font-family: 'BentonSans-Bold', Arial;
    display: inline-block;
    vertical-align: top;
}
.article_feedbacks .feedback_date {
    display: inline-block;
    vertical-align: top;
}
.article_feedbacks .feedback_text {
    margin-top: 10px;
}
